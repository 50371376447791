import { createSelector } from "@reduxjs/toolkit";

const user = (state) => state.auth;

export const selectUser = createSelector([user], (user) => user.user);

export const selectUserLoading = createSelector(
  [user],
  (loading) => loading.loading
);

export const selectError = createSelector([user], (error) => error.error);

export const selectUserSessions = createSelector(
  [user],
  (sessions) => sessions.sessions
);

export const selectSessionLoading = createSelector(
  [user],
  (loading) => loading.sessionsLoading
);

export const selectSessionError = createSelector(
  [user],
  (error) => error.sessionsError
);
