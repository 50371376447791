import React, { useContext, useState } from "react";
import Button from "../../../components/button/button";
import { MdAdd } from "react-icons/md";
import { BiSearch } from "react-icons/bi";
import { GoDotFill } from "react-icons/go";
import { MdOutlineEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import Avatar from "../../../components/avatar/avatar";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectProductLoading,
  selectProducts,
  selectSubProducts,
  selectSubProductsLoading,
} from "../../../data/selectors/productSelector";
import {
  fetchAllProducts,
  fetchAllSubProducts,
} from "../../../data/Reducers/productReducer";
import Loader from "../../../components/loader/loader";
import NoData from "../../../components/no-data/nodata";
import ModalContainer from "../../../components/modal-container/modal-container";
import { GlobalState } from "../../../data/Context";
import { toast } from "react-toastify";
import axios from "axios";
import { selectAllCompanies } from "../../../data/selectors/companySelector";
import Input from "../../../components/input/input";
import { handleError } from "../../../screens/login";

const Products = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState();
  const [uniqeCategories, setUniqueCategories] = useState([]);
  const [productId, setProductId] = useState("");
  const { getAllProductCategories, company, setCompany } =
    useContext(GlobalState);
  const subLoading = useSelector(selectSubProductsLoading);
  const subProducts = useSelector(selectSubProducts);

  const product = location.state;

  const toggleDeleteModal = (id) => {
    setProductId(id);
    setDeleteModal(!deleteModal);
  };

  const handleDeleteProduct = async () => {
    setLoading(true);
    try {
      const res = await axios.delete(`api/v1/product?product=${productId}`);
      setLoading(false);
      toast.success(res.data.message);
      dispatch(
        fetchAllSubProducts({
          companyId: product?.company,
          productId: product?._id,
        })
      );
      toggleDeleteModal();
    } catch (err) {
      setLoading(false);
      console.log(err);
     handleError(err, toast)
    }
  };

  useEffect(() => {
    dispatch(
      fetchAllSubProducts({
        companyId: product?.company,
        productId: product?._id,
      })
    );
  }, []);

  useEffect(() => {
    if (!product) return navigate("/products");
  }, []);

  return (
    <div className="p-4 overflow-auto no-scrollbar">
      <div className="flex items-center justify-between gap-4 flex-wrap">
        <h1 className="text-2xl font-bold">{product?.title}</h1>
        <div className="flex items-center gap-4">
          <div className="search flex items-center w-fit px-4 rounded-full lg:bg-white">
            <span className="text-gray-300">
              <BiSearch />
            </span>
            <input
              type="text"
              placeholder="Share here..."
              className="md:w-80 hidden lg:block outline-none border-none font-light text-sm h-10 px-2"
            />
          </div>
          <Button
            text={"Add Sub Product"}
            icon={<MdAdd />}
            handleButton={() =>
              navigate(
                `/products/add-subproduct?productName=${product.title}`,
                {
                  state: { product },
                }
              )
            }
          />
        </div>
      </div>
      <div className="bg-white rounded-xl mt-4 pb-20">
        <div className="flex items-center justify-between flex-w border-b border-gray-200 p-4">
          <div>
            <h2 className="font-semibold text-lg">sub products</h2>
          </div>
          {/* <div className="flex items-center gap-4">
            <h3 className="font-semibold text-sm hidden md:block">
              Preferred currency
            </h3>
            <select
              name=""
              className="border border-gray-200 rounded-lg p-2 outline-none"
            >
              <option value="">$</option>
            </select>
          </div> */}
        </div>
        <div className="overflow-x-auto no-scrollbar">
          <table className="w-full">
            <colgroup>
              <col span={6} />
            </colgroup>
            <thead>
              <tr className="border-b border-gray-200 align-top">
                <td className="p-2 capitalize font-semibold text-sm">
                  <p className="mb-2">Name</p>
                  <div className="search flex items-center w-fit px-4 rounded-lg border border-gray-200">
                    <input
                      type="text"
                      placeholder="Product name"
                      className="outline-none border-none font-light text-sm h-10 px-2"
                    />
                    <span className="text-gray-300">
                      <BiSearch />
                    </span>
                  </div>
                </td>
                <td className="p-2 capitalize font-semibold text-sm">
                  <p className="mb-2">Type</p>
                </td>
                <td className="p-2 capitalize font-semibold text-sm">
                  <p className="mb-2">Out</p>
                </td>
                <td className="p-2 capitalize font-semibold text-sm">
                  <p className="mb-2">Grade</p>
                </td>
                <td className="p-2 capitalize font-semibold text-sm">
                  <p className="mb-2">Action</p>
                </td>
              </tr>
            </thead>
            {!subLoading && subProducts?.docs && (
              <tbody className="relative">
                {subProducts?.docs.map((subProduct, id) => (
                  <tr
                    key={id}
                    className="border-b border-gray-200 font-semibold text-sm"
                  >
                    <td className="col p-4">
                      <Avatar
                        image={subProduct.image?.url}
                        name={subProduct.title}
                        title={subProduct.description}
                      />
                    </td>
                    <td className="col p-4">{subProduct.alloyType?.title}</td>
                    <td className="col p-4">{subProduct.alloySubrange?.out}</td>
                    <td className="col p-4">{subProduct.alloySubrange?.astm}</td>
                    <td className="col p-4">
                      <div className="flex items-center gap-4 text-lg">
                        <span
                          className="p cursor-pointer"
                          onClick={() =>
                            navigate("/products/add-subproduct", {
                              state: { subProduct, product },
                            })
                          }
                        >
                          <MdOutlineEdit />
                        </span>
                        <span
                          className="p cursor-pointer"
                          onClick={() => toggleDeleteModal(subProduct._id)}
                        >
                          <RiDeleteBin6Line />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          {subLoading ? (
            <div className="h-32 w-full flex items-center justify-center overflow-hidden">
              <Loader />
            </div>
          ) : (
            !subProducts?.docs?.length && (
              <div className="p-4 w-full flex items-center justify-center overflow-hidden">
                <NoData />
              </div>
            )
          )}
        </div>
      </div>
      <ModalContainer
        width={"max-w-sm"}
        show={deleteModal}
        close={toggleDeleteModal}
      >
        <div className="p-12">
          <form className="space-y-4">
            <div className="w-100">
              <p className="text-2xl text-center">
                Are you sure you want to Delete?
              </p>
              <div className="pt-4 flex items-center justify-center gap-8">
                <Button
                  type="button"
                  text={"Yes"}
                  loading={loading}
                  handleButton={handleDeleteProduct}
                />
                <button
                  className="h-10 rounded-lg border border-black font-semibold text-xs px-8"
                  type="button"
                  onClick={toggleDeleteModal}
                >
                  No
                </button>
              </div>
            </div>
          </form>
        </div>
      </ModalContainer>
    </div>
  );
};

export default Products;
