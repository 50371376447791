import { motion, useScroll, useTransform } from "framer-motion";

export const Scaler = ({ children }) => {
  const { scrollYProgress } = useScroll();
  const scale = useTransform(scrollYProgress, [0, 1], [0.8, 1]);

  const container = {
    visible: {
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };
  return (
    <motion.div
      variants={container}
      style={{
        scale,
      }}
    >
      {children}
    </motion.div>
  );
};

export const Zoom = ({ children }) => {
  const { scrollYProgress } = useScroll();
  console.log({ scrollYProgress });
  const y = useTransform(scrollYProgress, [0, 50], [0, 10]);
  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.5], {
    clamp: false,
  });
  return (
    <motion.div
      style={{
        y,
        scale,
      }}
    >
      <motion.div
        style={{
          //   y: scrollYProgress,
        //   scale: scrollYProgress,
        }}
      >
        {children}
      </motion.div>
    </motion.div>
  );
};
