import React, { useEffect } from "react";
import { BiSearch } from "react-icons/bi";
import NewsCard from "../../components/news-card/news-card";
import { useDispatch, useSelector } from "react-redux";
import { fetchContents } from "../../data/Reducers/contentSlice";
import {
  selectContent,
  selectContentLoading,
} from "../../data/selectors/contentSelector";
import Loader from "../../components/loader/loader";
import NoData from "../../components/no-data/nodata";

const IndustrialNews = () => {
  const dispatch = useDispatch();
  const loading = useSelector(selectContentLoading);
  const content = useSelector(selectContent);

  useEffect(() => {
    dispatch(fetchContents());
  }, []);
  return (
    <div className="p-4 overflow-auto no-scrollbar">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold">Products</h1>
        <div className="flex items-center gap-4">
          <div className="search flex items-center w-fit px-4 rounded-full bg-white">
            <span className="text-gray-300">
              <BiSearch />
            </span>
            <input
              type="text"
              placeholder="Share here..."
              className="md:w-80 outline-none border-none font-light text-sm h-10 px-2"
            />
          </div>
        </div>
      </div>
      <div className="mt-8">
        <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-4">
          {loading ? (
            <div className="flex items-center py-4 justify-center col-span-3">
              <Loader />
            </div>
          ) : content?.docs?.length <= 0 ? (
            <div className="flex items-center py-4 justify-center col-span-3">
              <NoData />
            </div>
          ) : (
            content?.docs?.map((item, idx) => (
              <NewsCard key={idx} item={item} />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default IndustrialNews;
