import { FaSearch } from "react-icons/fa";
// import LatestPost from "../components/latestPost";
import NewsCard from "../../components/news-card/news-card";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/button/button";
import { useDispatch, useSelector } from "react-redux";
import { selectContentCategory } from "../../data/selectors/contentSelector";
import { useEffect } from "react";
import { getContentCategories } from "../../data/Reducers/contentSlice";

const BlogDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const content = location.state;
  const category = useSelector(selectContentCategory);

  useEffect(() => {
    dispatch(getContentCategories());
  }, []);

  if (!content) return navigate("/content");
  return (
    <div className="max-w-6xl mx-auto px-4 bg-[#F6F7EB]">
      <div className="">
        <div className="">
          <div className="border rounded bg-white">
            <div className="w-full overflow-hidden h-[350px]">
              <img
                className="h-full w-full object-cover object-center"
                src={content?.image?.url}
                alt=""
              />
            </div>
            <article className="p-8">
              <h1 className="text-xl md:text-2xl font-semibold">
                {content.title}
              </h1>
              <div className="space-y-8 mt-4">{content?.content}</div>
            </article>
            <div className="p-8">
              <div className="flex">
                <p className="text-[#79745C]">
                  <span className="font-bold">Category: </span>
                  <span className="hover:text-[#3F88C5] cursor-pointer hover:underline">
                    {content?.category}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="space-y-4 py-4">
            <div className="bg-white p-8 rounded-md border border-[#7c79664b]">
              <div className="flex items-center gap-3 mb-3">
                <p className="text-lg">Category</p>
              </div>
              <div className="mt-6 flex items-center flex-wrap gap-8">
                {category.map((item) => (
                  <li className="capitalize">{item}</li>
                ))}
              </div>
            </div>
            <div className="bg-white p-8 rounded-md border border-[#7c79664b]">
              <div className="flex items-center gap-3 mb-4">
                <p className="text-lg">Latest Post</p>
              </div>
              <div className="grid md:grid-cols-2">
                <NewsCard />
                <NewsCard />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
