import React from "react";
import Input from "../../components/input/input";
import Button from "../../components/button/button";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCompanies,
  setActiveCompany,
} from "../../data/Reducers/companySlice";
import { handleError } from "../../screens/login";
import { selectActiveCompany } from "../../data/selectors/companySelector";

const AddComapny = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const company = location.state;
  const selectedCompany = useSelector(selectActiveCompany);
  const defaultValues = {
    name: company?.name || "",
    email: company?.email || "",
    address: company?.address || "",
    description: company?.description || "",
    telephone: company?.telephone || "",
  };
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const handleAddCompany = async (data) => {
    setLoading(true);
    try {
      let res;
      if (company) {
        res = await axios.put(
          `api/v1/user/manage-company?company=${company._id}`,
          data
        );
      } else {
        res = await axios.post("api/v1/user/manage-company", data);
      }
      dispatch(fetchCompanies());
      toast.success(res.data.message);
      navigate("/manage-company");
    } catch (err) {
      console.log(err);
      handleError(err, toast);
    }
    setLoading(false);
  };
  return (
    <div className="p-4 overflow-auto no-scrollbar">
      <div className="">
        <h1 className="text-2xl font-bold">Manage Companies</h1>
      </div>
      <div className="bg-white rounded-xl mt-4 pb-20">
        <div className="border-b border-gray-200 p-4">
          <h2 className="font-semibold text-lg">Add New Company</h2>
        </div>
        <div className="p-4 max-w-xl">
          <h2 className="font-semibold text-lg">Company Details</h2>
          <form onSubmit={handleSubmit(handleAddCompany)}>
            <div className="grid md:grid-cols-2 gap-4 my-4">
              <div>
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { value, onChange } }) => (
                    <Input
                      label={"Company Name"}
                      placeholder={"Enter your Company  name"}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                {errors.name && (
                  <p className="text-red-500 text-xs">
                    This field is required.
                  </p>
                )}
              </div>
              <div>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { value, onChange } }) => (
                    <Input
                      label={"Email address "}
                      placeholder={"Enter email address"}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                {errors.email && (
                  <p className="text-red-500 text-xs">
                    This field is required.
                  </p>
                )}
              </div>
              <div>
                <Controller
                  name="telephone"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field: { value, onChange } }) => (
                    <Input
                      label={"Phone Number"}
                      placeholder={"Enter Phone Number"}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                {errors.telephone && (
                  <p className="text-red-500 text-xs">
                    This field is required.
                  </p>
                )}
              </div>
              <div>
                <Controller
                  name="address"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field: { value, onChange } }) => (
                    <Input
                      label={"Address"}
                      placeholder={"Enter address"}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                {errors.address && (
                  <p className="text-red-500 text-xs">
                    This field is required.
                  </p>
                )}
              </div>
              <div className="col-span-2">
                <Controller
                  name="description"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field: { value, onChange } }) => (
                    <Input
                      label={"Description"}
                      placeholder={"Enter description"}
                      type={"textArea"}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                {errors.description && (
                  <p className="text-red-500 text-xs">
                    This field is required.
                  </p>
                )}
              </div>
            </div>
            <div className="flex justify-end mt-6">
              <Button text={"Add Company"} loading={loading} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddComapny;
