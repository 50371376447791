import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { clearErrors, getErrorText } from "./ErrorReducer";
import { SetAuthToken } from "../Config";
import axios from "axios";
import { fetchCompanies } from "./companySlice";
import {
  fetchCardStats,
  fetchIndicatorStats,
  fetchRevenueStats,
  fetchSalesStats,
} from "./dashboard";
import { fetchCurrency } from "./currencySlice";
// import { toast } from "react-toastify";

export const TOKEN = "STEEL_SALES";

let initialState = {
  isAuth: false,
  user: {},
  sessions: {},
  sessionsLoading: false,
  sessionsError: null,
  loading: false,
  error: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, { payload }) => {
      if (payload.token) localStorage.setItem(TOKEN, payload.token);
      state.isAuth = true;
      state.user = payload.data;
    },

    logout: (state) => {
      localStorage.removeItem(TOKEN);
      state.isAuth = false;
      state.user = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(loadUser.fulfilled, (state, { payload }) => {
      state.loading = false;
      // state.user = payload.data;
    });
    builder.addCase(loadUser.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error;
    });
    builder.addCase(updateProfile.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateProfile.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.user = payload.data;
    });
    builder.addCase(updateProfile.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error;
    });
    builder.addCase(fetchUserLoginSessions.pending, (state) => {
      state.sessionsLoading = true;
      state.sessionsError = null;
    });
    builder.addCase(fetchUserLoginSessions.fulfilled, (state, { payload }) => {
      state.sessionsLoading = false;
      state.sessions = payload.data;
    });
    builder.addCase(fetchUserLoginSessions.rejected, (state, { error }) => {
      state.sessionsLoading = false;
      state.sessionsError = error;
    });
  },
});

// Action creators are generated for each case reducer function
export const { login, logout } = userSlice.actions;

export default userSlice.reducer;

// GET USER INFO
export const loadUser = createAsyncThunk(
  "auth/loadUser",
  async (_, thunkAPI) => {
    let token = localStorage.getItem(TOKEN);
    if (token) {
      SetAuthToken(token);
      try {
        const res = await axios.get("api/v1/user");
        thunkAPI.dispatch(login(res.data));
        thunkAPI.dispatch(fetchCompanies());
        thunkAPI.dispatch(fetchCurrency());
      } catch (err) {
        console.log({ loadUserErr: err });
        return thunkAPI.rejectWithValue(err.message);
      }
    }
  }
);

// GET USER INFO
export const fetchUserLoginSessions = createAsyncThunk(
  "auth/fetchUserLoginSessions",
  async (_, thunkAPI) => {
    try {
      const res = await axios.get("api/v1/user/login");
      return res.data;
    } catch (err) {
      console.log({ loadUserErr: err });
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "auth/updateProfile",
  async (details, thunkAPI) => {
    try {
      const res = await axios.put("api/v1/user", details, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (err) {
      console.log({ loadUserErr: err });
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);
