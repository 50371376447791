import React, { useEffect, useRef, useState } from "react";
import SmallNav from "../../components/small-nav/small-nav";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Chrome } from "../../assets/svg/chrome.svg";
import { ReactComponent as Firefox } from "../../assets/svg/firefox.svg";
import { ReactComponent as Avatars } from "../../assets/svg/avatar.svg";
import { ReactComponent as Mastercard } from "../../assets/svg/mastercard.svg";
import { ReactComponent as MastercardText } from "../../assets/svg/text.svg";
import { ReactComponent as PdfIco } from "../../assets/svg/pdf-ico.svg";
import { SlOptionsVertical } from "react-icons/sl";
import { MdOutlineEdit } from "react-icons/md";
import { IoCheckmark } from "react-icons/io5";
import Button from "../../components/button/button";
import Input, { Checkbox, Toggle } from "../../components/input/input";
import Pricing from "../../components/pricing/pricing";
import ModalComponents from "../../components/ModalComponents";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input/input";
import ModalContainer from "../../components/modal-container/modal-container";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  selectError,
  selectSessionError,
  selectSessionLoading,
  selectUser,
  selectUserLoading,
  selectUserSessions,
} from "../../data/selectors/userSelector";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import {
  fetchUserLoginSessions,
  loadUser,
  updateProfile,
} from "../../data/Reducers/UserReducer";
import {
  selectPlans,
  selectProductLoading,
  selectSubscriptions,
} from "../../data/selectors/productSelector";
import Loader from "../../components/loader/loader";
import {
  fetchAllPlans,
  fetchAllSubscriptions,
} from "../../data/Reducers/productReducer";
import NoData from "../../components/no-data/nodata";
import {
  selectCurrency,
  selectCurrencyLoading,
} from "../../data/selectors/currencySelector";
import moment from "moment";
import {
  fetchCurrency,
  fetchCurrencyConfig,
} from "../../data/Reducers/currencySlice";
import { selectActiveCompany } from "../../data/selectors/companySelector";
import { handleError } from "../../screens/login";
import displayPic from "../../assets/pp-2.png";

const Settings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const menu = [
    "accounts",
    "security",
    "currency settings",
    "plan",
    "billing",
    "notification",
  ];
  const [activeNav, setActiveNav] = useState("");
  const handleNav = (route) => {
    navigate(`/settings?setting=${route}`);
  };

  useEffect(() => {
    if (!location.search) {
      navigate(`/settings?setting=accounts`);
      setActiveNav("accounts");
    }
  }, [location, location.search]);

  useEffect(() => {
    setActiveNav(
      location.search.split("=")[1]?.split("&")[0] ||
        location.search.split("=")[1]
    );
  }, [location.search]);
  return (
    <div className="p-4 overflow-auto no-scrollbar">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold">Settings</h1>
      </div>
      <div className="bg-white rounded-xl mt-4">
        <div className="flex items-center justify-between border-b border-gray-200 p-4 pb-0 w-full overflow-scroll no-scrollbar">
          <SmallNav menu={menu} active={activeNav} handleNav={handleNav} />
        </div>
        <div>
          {activeNav === menu[0] ? (
            <Account />
          ) : activeNav === menu[1] ? (
            <Security />
          ) : activeNav === "currency%20settings" ? (
            <CurrencySettings />
          ) : activeNav === menu[3] ? (
            location.search.split("&")[1]?.split("=")[1] === "check-out" ? (
              <ConfirmBilling />
            ) : (
              <Plan />
            )
          ) : activeNav === menu[4] ? (
            <Billing />
          ) : (
            activeNav === menu[5] && <Notification />
          )}
        </div>
      </div>
    </div>
  );
};

export default Settings;

const Account = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const userLoading = useSelector(selectUserLoading);
  const userError = useSelector(selectError);
  const [logo, setLogo] = useState(false);

  const [userDetails, setUserDetails] = useState({
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    telephone: user?.telephone || "",
    email: user?.email || "",
    companyName: user?.companyName || "",
  });
  const [phone, setPhone] = useState();

  const handleInput = (e) => {
    const { name, value } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
  };

  let handleChangeImage = (e) => {
    const file = e.target.files[0];
    let err = "";

    if (!file) return (err = `File, ${file?.name} does not exist`);
    if (!file.type.includes("image"))
      return (err = `File, ${file?.name} format not supported`);

    if (err) {
      return toast.error(err);
    } else {
      setLogo(file);
    }
  };

  const handleUpdateUser = () => {
    if (logo) {
      userDetails.intendedFileName = "image";
      userDetails.intendedFile = logo;
    }
    console.log({ userDetails });
    dispatch(updateProfile({ ...userDetails, telephone: phone }));
    if (userError) {
      toast.error(userError.response.data.message);
    } else {
      dispatch(loadUser());
      setLogo(null);
      toast.success("Profile updated successfully");
    }
  };
  return (
    <div className="p-4">
      <div className="border rounded-xl border-gray-200">
        <div className="p-4 border-b border-gray-200 flex justify-between items-center flex-wrap gap-4">
          <article>
            <p className="font-bold text-xl">Account</p>
            <p className="text-xs font-semibold text-[#7C8899]">
              Allows you to change your profile and account setting
            </p>
          </article>
          <Button text={"Add New Account"} />
        </div>
        <div className="grid lg:grid-cols-4 gap-8 p-4">
          <p className="text-sm font-semibold">User Details</p>
          <div className="col-span-3">
            <div className="grid md:grid-cols-2 gap-4">
              <Input
                label={"First Name"}
                placeholder="Enter your first name"
                name="firstName"
                value={userDetails.firstName}
                onChange={handleInput}
              />
              <Input
                label={"Last Name"}
                placeholder="Enter your last name"
                name="lastName"
                value={userDetails.lastName}
                onChange={handleInput}
              />
              {/* <Input
                label={"Phone Number"}
                placeholder="Enter your phone number"
                name="telephone"
                value={userDetails.telephone}
                onChange={handleInput}
              /> */}
              <div>
                <label className="font-semibold text-sm mb-2">
                  Phone Number
                </label>
                <div className="mt-2">
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={userDetails.telephone}
                    onChange={setPhone}
                    defaultCountry={"NG"}
                    limitMaxLength
                    className="flex items-center flex-col justify-center outline-none outline-0 border rounded-md h-10 border-gray-200 px-4 w-full placeholder:text-gray-400 placeholder:text-sm"
                  />
                </div>
              </div>
              <Input
                label={"Email Address"}
                placeholder="Enter your email address"
                type={"email"}
                name="email"
                value={userDetails.email}
                onChange={handleInput}
              />
            </div>
          </div>
        </div>
        <div className="grid lg:grid-cols-4 gap-8 p-4">
          <p className="text-sm font-semibold">Company Details</p>
          <div className="col-span-3">
            <div className="grid md:grid-cols-2 gap-4">
              <Input
                label={"Company Name"}
                placeholder="Enter your company name"
                value={userDetails.companyName}
                name={"companyName"}
                onChange={handleInput}
              />
            </div>
          </div>
        </div>
        <div className="p-4">
          <UpdateProfilePicture
            logo={logo}
            setLogo={setLogo}
            handleImage={handleChangeImage}
          />
        </div>
      </div>
      <div className="border rounded-xl border-gray-200 p-4 mt-4 py-8">
        <div className="grid sm:grid-cols-3 gap-4">
          <p className="text-md font-bold">Username</p>
          <div className="col-span-2">
            <p className="text-sm font-semibold">{user?.slug}</p>
          </div>
          <p className="text-md font-bold">Email</p>
          <div className="col-span-2">
            <p className="text-sm font-semibold">{user.email}</p>
          </div>
          <p className="text-md font-bold">Login authentication method</p>
          <div className="col-span-2">
            <p className="text-sm font-semibold">{user?.is2FAType}</p>
          </div>
        </div>
        <div className="col-span-3 flex items-center md:justify-end gap-4 mt-8">
          <button className="h-10 text-xs px-8 border border-black rounded-md font-semibold">
            Cancel
          </button>
          <Button
            text={"save"}
            handleButton={handleUpdateUser}
            loading={userLoading}
          />
        </div>
      </div>
    </div>
  );
};

const Security = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [mode, setMode] = useState("password");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const sessions = useSelector(selectUserSessions);
  const sessionsLoading = useSelector(selectSessionLoading);
  const sessionsError = useSelector(selectSessionError);
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPasswordewPassword: "",
    },
  });

  const validatePassword = (value) => {
    // Use watch to get the value of the password field
    const password = watch("newPassword");
    // Check if the passwords match
    return value === password || "Passwords do not match";
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const res = await axios.put("api/v1/user/update-password", {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      });
      setLoading(false);
      toast.success(res.data.message);
      toggleModal();
    } catch (err) {
      console.log(err);
      setLoading(false);
      handleError(err, toast);
    }
  };

  const toggleModal = (mode) => {
    setMode(mode);
    setIsOpen(!isOpen);
  };

  if (sessionsError) {
    toast.error(sessionsError.message);
  }

  useEffect(() => {
    dispatch(fetchUserLoginSessions());
  }, []);
  return (
    <div>
      <div>
        <div className="p-4 border-b border-gray-200 flex justify-between items-center gap-4 flex-wrap">
          <article>
            <p className="font-bold text-xl">Password</p>
            <p className="text-xs font-semibold text-[#7C8899]">
              Update password for enhanced account security.
            </p>
          </article>
          <Button
            text={"Manage Password"}
            handleButton={() => toggleModal("password")}
          />
        </div>
        <div className="p-4 border-b border-gray-200 flex justify-between items-center gap-4 flex-wrap">
          <article>
            <p className="font-bold text-xl">Two-factor authentication.</p>
            <p className="text-xs font-semibold text-[#7C8899]">
              Add an extra layer of protection to your account.
            </p>
          </article>
          <Button
            text={"Manage Authentication"}
            handleButton={() => navigate("/settings/two-factor-authentication")}
          />
        </div>
        <div className="p-4">
          <div className="border rounded-xl border-gray-200">
            <div className="p-4 border-b border-gray-200 flex justify-between items-center gap-4 flex-wrap">
              <article>
                <p className="font-bold text-xl">Two-factor authentication.</p>
                <p className="text-xs font-semibold text-[#7C8899]">
                  Add an extra layer of protection to your account.
                </p>
              </article>
              <button className="h-10 px-8 text-xs font-semibold capitalize border-2 border-red-600 text-red-600 rounded-md">
                Logout All Sessions
              </button>
            </div>
            <div className="w-full overflow-x-scroll scrollbar-hide">
              <table className="w-full whitespace-nowrap">
                <thead>
                  <tr className="text-sm font-semibold bg-gray-200">
                    <td className="p-4">Name</td>
                    <td className="p-4">Location</td>
                    <td className="p-4">Most recenty acitities</td>
                    <td className="p-4"></td>
                  </tr>
                </thead>
                <tbody className="font-semibold">
                  {sessionsLoading ? (
                    <tr>
                      <td colSpan={4}>
                        <div className="flex w-full p-4 justify-center">
                          <Loader />
                        </div>
                      </td>
                    </tr>
                  ) : sessions?.docs?.length > 0 ? (
                    sessions.docs.map((session) => (
                      <tr>
                        <td className="p-4 col">
                          <div className="flex items-center gap-1">
                            {/* <span>
                              <Chrome />
                            </span> */}
                            <span>
                              {session.deviceProperties?.client?.name}
                            </span>
                          </div>
                        </td>
                        <td className="p-4 col">Lagos, Nigeria</td>
                        <td className="p-4 col">
                          {session.updatedAt?.replace("T", " / ")}
                        </td>
                        <td className="p-4 col">
                          <span className="cursor-pointer">
                            <SlOptionsVertical />
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={4}>
                        <div className="flex w-full p-4 justify-center">
                          <NoData />
                        </div>
                      </td>
                    </tr>
                  )}
                  {/* <tr>
                    <td className="p-4 col">
                      <div className="flex items-center gap-1">
                        <span>
                          <Firefox />
                        </span>
                        <span>Mozila Firefox</span>
                      </div>
                    </td>
                    <td className="p-4 col">Lagos, Nigeria</td>
                    <td className="p-4 col">Current Session</td>
                    <td className="p-4 col">
                      <span className="cursor-pointer">
                        <SlOptionsVertical />
                      </span>
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* modal */}
      <ModalContainer show={isOpen} close={toggleModal}>
        {mode === "password" && (
          <div className="">
            <div className="p-3 bg-gray-400">
              <h3 className="text-xl font-bold">Change Password</h3>
            </div>
            <div className="p-4">
              <form
                className="m max-w-xl mx-auto"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="grid sm:grid-cols-2 gap-4">
                  <div className="col-span-2">
                    <Controller
                      name="oldPassword"
                      control={control}
                      rules={{
                        required: "This field is required",
                        pattern: {
                          value:
                            /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).{8,}$/g,
                          message:
                            "Your password must have at least one uppercase letter, one lowercase letter, one digit, one special character, and a minimum length of 8 characters.",
                        },
                      }}
                      render={({ field: { value, onChange } }) => (
                        <Input
                          placeholder="Old Password"
                          type={"password"}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                    {errors.oldPassword && (
                      <p className="text-red-500 text-xs">
                        {errors.oldPassword.message}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <Controller
                      name="newPassword"
                      control={control}
                      rules={{
                        required: "This field is required",
                        pattern: {
                          value:
                            /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).{8,}$/g,
                          message:
                            "Your password must have at least one uppercase letter, one lowercase letter, one digit, one special character, and a minimum length of 8 characters.",
                        },
                      }}
                      render={({ field: { value, onChange } }) => (
                        <Input
                          placeholder="Password"
                          type={"password"}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                    {errors.newPassword && (
                      <p className="text-red-500 text-xs">
                        {errors.newPassword.message}
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <Controller
                      name="confirmNewPassword"
                      control={control}
                      rules={{
                        required: "This field is required",
                        validate: validatePassword,
                      }}
                      render={({ field: { value, onChange } }) => (
                        <Input
                          placeholder="Confirm Password"
                          type={"password"}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                    {errors.confirmNewPassword && (
                      <p className="text-red-500 text-xs">
                        {errors.confirmNewPassword.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-span-3 flex items-center justify-end gap-8 mt-8">
                  <Button text={"Submit"} loading={loading} />
                  <button
                    type="reset"
                    className="h-10 text-xs px-8 border border-black rounded-md font-semibold"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </ModalContainer>
    </div>
  );
};

const Billing = () => {
  const dispatch = useDispatch();
  const subscriptions = useSelector(selectSubscriptions);
  const subscriptionLoading = useSelector(selectProductLoading);

  useEffect(() => {
    dispatch(fetchAllSubscriptions());
  }, []);
  return (
    <div className="p-4">
      <h3 className="font-bold text-xl">Billing</h3>
      <div className="flex items-center gap-4 py-8 flex-wrap">
        <div className="border border-gray-200 rounded-2xl max-w-fit">
          <div className="p-4 border-b border-grayy-200">
            <div className="flex items-end gap-12">
              <div>
                <h3 className="md:text-lg font-bold">Basic Plan</h3>
                <p className="text-[#7C8899] font-bold text-xs">
                  Explore Unlimited feature when you upgrade
                </p>
              </div>
              <p className="text-xl font-bold">
                <span className="md:text-3xl text-xl">$20</span> per month
              </p>
            </div>
            <div className="flex items-center gap-12 justify-between mt-8">
              <div>
                <Avatars />
              </div>
              <p className="md:text-xl text-lg font-bold">15 of 30 Users</p>
            </div>
          </div>
          <div className="p-4 flex items-center justify-between gap-8">
            <p className="md:text-xl text-sm font-bold">
              <span className="text-[#7C8899]">Payment Due:</span> May 30, 2024
            </p>
            <Button text={"Upgrade plan"} />
          </div>
        </div>
        <div className="border border-gray-200 rounded-2xl max-w-fit p-4">
          <div className="flex items-end gap-12">
            <div>
              <h3 className="md:text-lg font-bold">Basic Plan</h3>
              <p className="text-[#7C8899] font-bold text-xs">
                Explore Unlimited feature when you upgrade
              </p>
            </div>
            <button className="h-10 px-8 rounded-full border whitespace-nowrap border-gray-200 text-[#7C8899] font-semibold text-sm">
              Add payment
            </button>
          </div>
          <div className="p-4 my-4 border border-gray-200 rounded-2xl flex justify-between gap-4">
            <div className="flex items-center gap-2">
              <Mastercard />
              <article>
                <MastercardText />
                <p className="md:text-lg font-bold">Expiry 08/2024</p>
              </article>
            </div>
            <span className="md:text-2xl">
              <MdOutlineEdit />
            </span>
          </div>
        </div>
      </div>
      <div className="border rounded-xl border-gray-200 mt-4 pb-16">
        <div className="p-4 border-b border-gray-200 flex justify-between items-center gap-4 flex-wrap">
          <article>
            <p className="font-bold md:text-xl">Billing History</p>
            <p className="text-xs font-semibold text-[#7C8899]">
              Dowload your previous plan receipts and usage details
            </p>
          </article>
          <Button text={"Download all"} />
        </div>
        <div className="w-full overflow-scroll scrollbar-hide">
          <table className="w-full whitespace-nowrap">
            <thead>
              <tr className="text-sm font-semibold bg-gray-200">
                <td className="p-4">Name</td>
                <td className="p-4">Billing date</td>
                <td className="p-4">Amount</td>
                <td className="p-4">Plan</td>
                {/* <td className="p-4">Users</td> */}
                <td className="p-4">Status</td>
                <td className="p-4"></td>
              </tr>
            </thead>
            <tbody className="font-semibold">
              {subscriptionLoading ? (
                <tr>
                  <td colSpan={6} className="text-center">
                    <div className="w-full flex p-4 justify-center">
                      <Loader />
                    </div>
                  </td>
                </tr>
              ) : subscriptions.docs?.length > 0 ? (
                subscriptions.docs?.map((sub) => (
                  <tr className="border-b-2 border-gray-300">
                    <td className="p-4 col">
                      <div className="flex items-center gap-1">
                        <span>
                          <PdfIco />
                        </span>
                        <span className="underline">
                          <a href={sub.invoice_pdf} target="_blank">
                            {sub.name}
                          </a>
                        </span>
                      </div>
                    </td>
                    <td className="p-4 col">29 Feb. 2024</td>
                    <td className="p-4 col">${sub.plan?.amount}</td>
                    <td className="p-4 col">{sub.plan?.title}</td>
                    {/* <td className="p-4 col">12 user</td> */}
                    <td className="p-4 col">
                      <div className="flex items-center gap-2 px-4 py-2 rounded-full text-[#2ED8B6] bg-[#2ED8B61A] font-bold">
                        <span>
                          <IoCheckmark />
                        </span>
                        <span>Paid</span>
                      </div>
                    </td>
                    <td className="p-4 col">
                      <span className="cursor-pointer">
                        <SlOptionsVertical />
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className="text-center">
                    <div className="w-full flex p-4 justify-center">
                      <NoData />
                    </div>
                  </td>
                </tr>
              )}
              {/* <tr className="border-b-2 border-gray-300">
                <td className="p-4 col">
                  <div className="flex items-center gap-1">
                    <span>
                      <PdfIco />
                    </span>
                    <span>Invoice-2024/09.pdf</span>
                  </div>
                </td>
                <td className="p-4 col">29 Feb. 2024</td>
                <td className="p-4 col">$530</td>
                <td className="p-4 col">Basic plan</td>
                <td className="p-4 col">12 user</td>
                <td className="p-4 col">
                  <div className="flex items-center gap-2 px-4 py-2 rounded-full text-[#2ED8B6] bg-[#2ED8B61A] font-bold">
                    <span>
                      <IoCheckmark />
                    </span>
                    <span>Paid</span>
                  </div>
                </td>
                <td className="p-4 col">
                  <span className="cursor-pointer">
                    <SlOptionsVertical />
                  </span>
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const Plan = () => {
  const dispatch = useDispatch();
  const plans = useSelector(selectPlans);
  const planLoading = useSelector(selectProductLoading);

  useEffect(() => {
    dispatch(fetchAllPlans());
  }, []);
  return (
    <div className="p-8">
      <div className="max-w-md text-center mx-auto">
        <h2 className="text-2xl font-bold">Choose your right plan</h2>
        <p className="text-sm text-[#7C8899] font-bold">
          Enjoy the available feature from any of our plans. we are her to serve
          you better
        </p>
      </div>
      <div className="flex justify-center gap-6 mt-8 flex-wrap">
        {planLoading ? (
          <Loader />
        ) : (
          plans &&
          plans?.docs?.map((plan, id) => <Pricing key={id} plan={plan} />)
        )}
      </div>
    </div>
  );
};

const ConfirmBilling = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [secret, setSecret] = useState("");
  const stripe = useStripe();
  const elements = useElements();

  const plan = location.state;
  const user = useSelector(selectUser);

  let handleSubmit = async (e) => {
    e.preventDefault();
    if (!location.state)
      return toast.warn("Membership plan required", { autoClose: 5000 });
    setLoading(true);
    try {
      let customerName = `${user?.firstName || ""} ${user?.lastName || ""}`,
        billing_details = {
          name: customerName,
          email: user?.email,
        };
      const result = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
        billing_details,
      });
      if (result.error) {
        toast.error(result?.error?.message);
      } else {
        var res = await axios.put("api/v1/subscription", {
          email: user?.email,
          payment_method: result.paymentMethod.id,
          customerName,
          plan: plan?._id,
        });

        setSecret(res.data.data);
        if (res.data.status === "requires_action") {
          const { error, paymentIntent } = await stripe.confirmCardPayment(
            secret || res.data.data
          );
          // https://stripe.com/docs/payments/card-element
          if (error) {
            toast.error(`Payment failed: ${error.message}`, {
              autoClose: false,
            });
          } else {
            toast.success(`Payment status: ${paymentIntent.status}`, {
              autoClose: 5000,
            });
            try {
              var res2 = await axios.post("api/v1/subscription", {
                paymentID: paymentIntent.id,
                stripeId: res?.data?.stripeId,
                plan: plan?._id,
                email: user?.email,
              });
              toast.success(res2.data.msg, { autoClose: 5000 });
              // dispatch(getAllMessages());
              return navigate("/");
            } catch (error) {
              if (error?.response?.data?.data) {
                error?.response?.data?.data.forEach((element) => {
                  toast.error(element.msg);
                });
              }
            }
          }
        } else {
          toast.success(`Payment status: ${res.data.status}`, {
            autoClose: 5000,
          });
          try {
            let res2 = await axios.post("api/v1/subscription", {
              paymentID: res?.data?.payment_intent?.id,
              stripeId: res?.data?.stripeId,
              plan: plan?._id,
              email: user?.email,
            });
            toast.success(res2?.data?.msg, { autoClose: 5000 });
            return navigate("/");
          } catch (error) {
            if (error?.response?.data?.data) {
              error?.response?.data?.data.forEach((element) => {
                toast.error(element.msg);
              });
            }
          }
        }
      }
    } catch (error) {
      console.log({ error });
      handleError(error, toast);
    }
    setLoading(false);
  };
  return (
    <div>
      <div className="">
        <div className="p-4 border-b border-gray-200 flex justify-between items-center">
          <article className="block">
            <p className="font-bold text-xl">Billing Sumary</p>
            <p className="text-xs font-semibold text-[#7C8899]">
              Allows you to change your profile and account setting
            </p>
          </article>
        </div>
        <div className="grid lg:grid-cols-4 gap-8 p-4">
          <p className="text-sm font-semibold">Personal Details</p>
          <div className="col-span-3">
            <div className="grid md:grid-cols-2 gap-4">
              <Input
                label={"Full Name"}
                placeholder="Enter your company name"
                value={`${user?.lastName} ${user?.firstName}`}
              />
              <Input label={"Plan"} value={plan?.title} />
            </div>
          </div>
        </div>
        <div className="grid lg:grid-cols-4 gap-8 p-4">
          <p className="text-sm font-semibold">Card Details</p>
          <div className="col-span-3">
            <form>
              <div className="grid grid-cols-2 gap-4">
                <div className="relative mb-4 rounded border border-text col-span-2">
                  <CardNumberElement className="w-full bg-transparent p-3 leading-[1.6] outline-none placeholder:opacity-60" />
                  <label
                    className="absolute bg-white -top-2 left-2 text-xs text-text"
                    htmlFor={"Card number"}
                  >
                    Card number
                  </label>
                </div>
                <div className="relative mb-4 rounded border border-text">
                  <CardExpiryElement className="min-h-[auto] w-full bg-transparent p-3 leading-[1.6] outline-none placeholder:opacity-60" />
                  <label
                    className="absolute bg-white -top-2 left-2 text-xs text-text"
                    htmlFor={"Expiration Date"}
                  >
                    Expiration Date
                  </label>
                </div>
                <div className="relative mb-4 rounded border border-text">
                  <CardCvcElement
                    type="password"
                    className="min-h-[auto] w-full bg-transparent p-3 leading-[1.6] outline-none placeholder:opacity-60"
                  />
                  <label
                    className="absolute bg-white -top-2 left-2 text-xs text-text"
                    htmlFor={"Card Security Code"}
                  >
                    Card Security Code
                  </label>
                </div>
              </div>
              {/* <div>
                <CardElement />
              </div> */}
              <div className="flex justify-center my-8">
                <Button
                  // buttonType={"primary"}
                  text={"Subscribe"}
                  loading={loading}
                  handleButton={handleSubmit}
                  type={"submit"}
                  css="w-40 h-8 px-6 rounded-md font-light text-sm whitespace-nowrap bg-main text-white"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const Notification = () => {
  return (
    <div className="p-4">
      <div className="border rounded-xl border-gray-200">
        <div className="p-4 border-b border-gray-200">
          <article>
            <p className="font-bold text-xl">Notification</p>
          </article>
        </div>
        <div className="grid lg:grid-cols-4 gap-8 p-4">
          <p className="text-sm font-semibold">Email Notification:</p>
          <div className="col-span-3">
            <Toggle />
          </div>
          <p className="text-sm font-semibold">SMS Notification:</p>
          <div className="col-span-3">
            <Toggle />
          </div>
          <p className="text-sm font-semibold">When you get Email</p>
          <div className="col-span-3">
            <Checkbox label={"You have new notifications"} />
          </div>
          <p className="text-sm font-semibold">When to Escalate Emails</p>
          <div className="col-span-3 flex flex-col gap-y-2">
            <Checkbox label={"For new order"} />
            <Checkbox label={"Member Registration"} />
            <Checkbox label={"New Membership approval"} />
          </div>
        </div>
        <div className="flex items-center sm:justify-end gap-4 mt-12 mb-8 p-4">
          <button
            type="reset"
            className="h-10 px-4 font-medium border border-black rounded-md whitespace-nowrap"
          >
            Cancel
          </button>
          <Button text={"Save"} />
        </div>
      </div>
    </div>
  );
};

const CurrencySettings = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const currency = useSelector(selectCurrency);
  const currencyLoading = useSelector(selectCurrencyLoading);
  const company = useSelector(selectActiveCompany);
  const defaultData = {
    denomination: "",
    startDate: "",
    endDate: "",
    actualDate: "",
  };

  const [formData, setFormData] = useState(defaultData);

  const handleSelect = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSetCurrency = async (e) => {
    e.preventDefault();
    setLoading(true);
    const empty = Object.entries(formData)
      .filter(([key, value]) => value.length === 0)
      .filter(([k, v]) => k !== "actualDate");
    if (empty.length) {
      setLoading(false);
      return empty.map(([k, v]) => {
        toast.error(`${k} is required`);
      });
    }
    try {
      const res = await axios.post(
        `api/v1/exchange/user-configuration`,
        formData,
        {
          headers: {
            "company-id": company._id,
          },
        }
      );
      toast.success(res.data.message);
      setFormData(defaultData);
      dispatch(fetchCurrencyConfig());
    } catch (err) {
      console.log({ err });
      handleError(err, toast);
    }
    setLoading(false);
  };

  useEffect(() => {
    dispatch(fetchCurrency());
  }, []);
  return (
    <div className="p-4">
      <div className="border rounded-xl border-gray-200">
        <div className="p-4 border-b border-gray-200">
          <article>
            <p className="font-bold text-xl">Exchange rate currency</p>
          </article>
        </div>
        <div>
          <div className="grid md:grid-cols-2 gap-4 p-4 max-w-xl">
            <Input
              label={"Preferred Currency "}
              selectHolder={"Select Currency"}
              type={"select"}
              value={formData.denomination}
              name={"denomination"}
              onChange={handleSelect}
              options={currency}
            />
            <div></div>
            <Input
              label={"Date Started"}
              type={"date"}
              value={formData.startDate}
              name={"startDate"}
              onChange={handleSelect}
            />
            <Input
              label={"Date Ended"}
              type={"date"}
              value={formData.endDate}
              name={"endDate"}
              onChange={handleSelect}
            />
          </div>
          <div className="gap-4">
            <div className="max-w-xl p-4">
              <label className="text-md font-semibold">
                Brief instructions
              </label>
              <div className="bg-[#D9D9D9] p-3 rounded-lg text-sm font-medium">
                When users perform transactions or calculations involving pounds
                and another currency, apply the appropriate exchange rate to
                convert the amounts accurately. This may involve multiplying or
                dividing the original amount by the exchange rate, depending on
                the direction of the conversion
              </div>
            </div>
            <div className="flex items-center my-4 gap-4 p-4">
              <button
                className="h-10 text-xs px-8 border border-black rounded-md font-semibold whitespace-nowrap"
                onClick={() => setFormData(defaultData)}
              >
                Clear all
              </button>
              <Button
                text={"Apply changes"}
                loading={loading}
                handleButton={handleSetCurrency}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const UpdateProfilePicture = ({ logo, setLogo, handleImage }) => {
  const user = useSelector(selectUser);
  console.log({ user });
  const fileInputRef = useRef(null);

  return (
    <div>
      <div className="flex items-center gap-6">
        <img
          src={
            logo ? URL.createObjectURL(logo) : user?.image?.url || displayPic
          }
          alt="displayPic"
          className="h-[80px] w-[80px] rounded-full object-cover"
        />
        <Button
          buttonType={"outlineBlack"}
          text={logo ? "remove" : "change"}
          width={"norms"}
          onClick={() => {
            logo ? setLogo(null) : fileInputRef.current.click();
          }}
        />
        <input
          ref={fileInputRef}
          type="file"
          // accept="image/*"
          style={{ display: "none" }}
          onChange={handleImage}
          name="image"
        />
      </div>
    </div>
  );
};
