import { createSelector } from "@reduxjs/toolkit";

const currencySlice = (state) => state.currency;

export const selectCurrency = createSelector(
  [currencySlice],
  (currency) => currency.currency
);

export const selectCurrencyLoading = createSelector(
  [currencySlice],
  (loading) => loading.loading
);
