import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Google } from "../assets/svg/google.svg";
import Input from "../components/input/input";
import Button from "../components/button/button";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "axios";
import { ReuseBox } from "./register";
import { useDispatch } from "react-redux";
import { loadUser, login } from "../data/Reducers/UserReducer";
import { SetAuthToken } from "../data/Config";
import { resendToken } from "./verify-email";
import { fetchCompanies } from "../data/Reducers/companySlice";
import ModalContainer from "../components/modal-container/modal-container";
import { OtpComponent } from "../components/ModalComponents";
import { useGoogleLogin } from "@react-oauth/google";

export const handleError = (error, toast) => {
  if (!error.response) {
    return toast.error(error.message);
  } else if (error.response.data.message) {
    return toast.error(error.response.data.message);
  } else {
    return error.response?.data?.error?.forEach((err) =>
      toast.error(err?.msg || err?.message)
    );
  }
};

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [code, setCode] = useState("");
  const [otpModal, setOtpModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const toggleOtpModal = () => {
    setOtpModal(!otpModal);
  };

  const SubmitOtp = async (e) => {
    e.preventDefault();
    if (!code) return toast.error("OTP code is required");
    setIsLoading(true);
    try {
      const res = await axios.put(
        "api/v1/user/is2fa-authenticate",
        { token: code },
        {
          headers: {
            Authorization: holdUser,
          },
        }
      );
      toast.success(res.data.message);
      SetAuthToken(holdUser);
      dispatch(login(res.data));
      dispatch(loadUser());
      dispatch(fetchCompanies());
      navigate("/");
      setIsLoading(false);
    } catch (err) {
      console.log({ err });
      setIsLoading(false);
      handleError(err, toast);
    }
  };

  const [holdUser, setHoldUser] = useState();

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      gmailLogin({
        access_token: tokenResponse.access_token,
        token_type: tokenResponse.token_type,
      });
    },
    onError: (error) => handleError(error, toast),
  });

  const gmailLogin = async (data) => {
    setLoading(true);
    try {
      const res = await axios.post("api/v1/user/login/google", data);
      toast.success(res.data.message);
      SetAuthToken(res.data.token);
      dispatch(login(res.data));
      dispatch(fetchCompanies());
      navigate("/");
    } catch (err) {
      console.log(err);
      handleError(err, toast);
    }
    setLoading(false);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const res = await axios.post("api/v1/user/login", data);
      toast.success(res.data.message);
      setLoading(false);
      if (res.data.is2FAEnabled === "enable") {
        setHoldUser(res.data.token);
        toggleOtpModal();
      } else {
        SetAuthToken(res.data.token);
        dispatch(login(res.data));
        dispatch(fetchCompanies());
        navigate("/");
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      if (
        err.response?.data?.message ===
        "Please verify your email to continue..."
      ) {
        try {
          await resendToken(data.email);
          return navigate("/verify-email");
        } catch (err) {
          console.log(err);
          handleError(err, toast);
        }
      }
      handleError(err, toast);
    }
  };
  return (
    <div>
      <ReuseBox>
        <div className="flex h-full items-center flex-col justify-center">
          <div className="text-center max-w-xl mx-auto my-4">
            <h1 className="font-semibold text-2xl">Sign in to Alloy</h1>
            <p className="text-sm">Measure your monthly sales performance</p>
          </div>
          <form className="max-w-md mx-auto" onSubmit={handleSubmit(onSubmit)}>
            <div className="grid sm:grid-cols-2 gap-4">
              <div className="col-span-2">
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: true,
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Invalid email format",
                    },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <Input
                      placeholder="Email"
                      type={"email"}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                {errors.email && (
                  <p className="text-red-500 text-xs">
                    This field is required.
                  </p>
                )}
              </div>
              <div className="col-span-2">
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: "This field is required",
                    pattern: {
                      value:
                        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).{8,}$/g,
                      message:
                        "Your password must have at least one uppercase letter, one lowercase letter, one digit, one special character, and a minimum length of 8 characters.",
                    },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <Input
                      placeholder="Password"
                      type={"password"}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                {errors.password && (
                  <p className="text-red-500 text-xs">
                    {errors.password.message}
                  </p>
                )}
              </div>
            </div>
            <div className="text-right">
              <Link
                to={"/forgot-password"}
                className="italic text-right text-xs text-blue-500"
              >
                Forgot Password
              </Link>
            </div>
            <div className="mt-4">
              <p className="text-gray-400 text-xs">
                By creating an account you agree to our{" "}
                <span className="font-semibold text-black">privacy policy</span>{" "}
                and{" "}
                <span className="font-semibold text-black">
                  electronic communication policy
                </span>
              </p>
            </div>
            <div className="mt-8">
              <Button
                text={"Sign in"}
                style={"w-full"}
                loading={loading}
              ></Button>
            </div>
            <div className="flex items-center gap-2 my-4">
              <div className="border border-gray-200 w-full"></div>
              <p className="font-bold whitespace-nowrap text-xs">
                Or with email
              </p>
              <div className="border border-gray-200 w-full"></div>
            </div>
            <div>
              <button
                className="h-12 w-full border rounded-md border-gray-400 flex items-center justify-center gap-2"
                onClick={handleGoogleLogin}
              >
                <span>
                  <Google />
                </span>
                <span className="font-semibold text-sm">
                  Sign in with Google
                </span>
              </button>
            </div>
            <div className="text-center mt-4">
              <p className="text-xs font-semibold">
                Don’t have an account?{" "}
                <span
                  className="font-bold hover:text-yellow-600 cursor-pointer"
                  onClick={() => navigate("/register")}
                >
                  Sign Up
                </span>
              </p>
            </div>
          </form>
        </div>
      </ReuseBox>
      <ModalContainer
        show={otpModal}
        close={toggleOtpModal}
        width={"max-w-fit"}
      >
        <div className="p-4">
          <form className="" onSubmit={SubmitOtp}>
            <div className="flex flex-col gap-2 items-center justify-center">
              <h1 className="text-center text-lg font-semibold">Verify Otp</h1>
              <p className="text-xs font-medium text-gray-500 max-w-xs mx-auto text-center">
                Please check your indox and enter the verification code below to
                verify your email address.
              </p>
              <div className="p-4 flex justify-center">
                <OtpComponent
                  stateData={code}
                  textChange={(data) => {
                    setCode(data);
                  }}
                  css="borderColor"
                  loading={loading}
                  numInputs={6}
                />
              </div>
            </div>
            <div className="mt-8 flex justify-center">
              <Button
                text={"Verify"}
                // style={"w-"}
                loading={isLoading}
              ></Button>
            </div>
          </form>
        </div>
      </ModalContainer>
    </div>
  );
};

export default Login;
