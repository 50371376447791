import { useEffect, useState } from "react";
import { Toggle } from "../../components/input/input";
import ModalContainer from "../../components/modal-container/modal-container";
import { OtpComponent } from "../../components/ModalComponents";
import Button from "../../components/button/button";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input/input";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  selectError,
  selectUser,
  selectUserLoading,
} from "../../data/selectors/userSelector";
import { loadUser, updateProfile } from "../../data/Reducers/UserReducer";

const TwoFactorAuth = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [mode, setMode] = useState("");
  const [code, setCode] = useState("");
  const [type, setType] = useState("");
  const [disable, setDisable] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggleModal = (mode) => {
    setMode(mode);
    setIsOpen(!isOpen);
  };
  const user = useSelector(selectUser);
  const userLoading = useSelector(selectUserLoading);
  const userError = useSelector(selectError);
  const [checked, setChecked] = useState();
  const dispatch = useDispatch();
  const [phone, setPhone] = useState(user?.telephone || "");

  const activateOtp = async () => {
    setIsLoading(true);
    try {
      let res;
      if (user?.is2FAEnabled === "enable") {
        res = await axios.put("api/v1/user/is2fa-disable", {
          token: code,
        });
      } else {
        res = await axios.put("api/v1/user/is2fa-validate", {
          token: code,
        });
      }
      dispatch(loadUser());
      toast.success(res.data.message);
      toggleModal();
      setCode("");
      setIsLoading(false);
    } catch (err) {
      console.log();
      setIsLoading(false);
      if (err.response?.data?.error) {
        err.response?.data?.error.forEach((msg) => {
          toast.error(msg.message);
        });
      }
      toast.error(err.response.data?.message);
    }
  };

  const handlePhone2fa = () => {
    setLoading(true);
    if (!user.telephone) {
      handleUpdateUser();
      if (userError) {
        return toast.error("Update phone number error");
      }
      setType("telephone");
    } else {
      setType("telephone");
    }
  };

  const handleUpdateUser = () => {
    dispatch(updateProfile({ telephone: phone }));
    if (userError) {
      toast.error(userError.response.data.message);
    } else {
      dispatch(loadUser());
      toast.success("Profile updated successfully");
    }
  };

  useEffect(() => {
    if (user?.is2FAEnabled === "enable") {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [user?.is2FAEnabled]);

  useEffect(() => {
    const initiateOtp = async () => {
      setLoading(true);
      try {
        let res;
        if (user?.is2FAEnabled === "enable") {
          res = await axios.put("api/v1/user/is2fa-initiate", { type });
        } else {
          res = await axios.post("api/v1/user/is2fa-initiate", { type });
        }
        toggleModal("email-otp");
        dispatch(loadUser());
        toast.success(res.data.message);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err.response?.data?.error) {
          err.response?.data?.error.forEach((msg) => {
            toast.error(msg.message);
          });
        }
        toast.error(err.response?.data?.message);
      }
      setType("");
    };
    if (type) {
      initiateOtp();
    }
  }, [type]);
  return (
    <div className="p-4 overflow-auto no-scrollbar">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold">Settings</h1>
      </div>
      <div className="bg-white rounded-xl mt-4">
        <div className="flex items-center justify-between border-b border-gray-200 p-4">
          <h2 className="font-semibold text-lg">Two-Factor authentication</h2>
          <div className="flex items-center gap-4">
            <Toggle
              onChange={() => {
                if (user?.is2FAEnabled === "enable") {
                  setType(user?.is2FAType);
                }
              }}
              checked={checked}
              disabled={loading}
            />
          </div>
        </div>
        <div className="p-4 max-w-4xl">
          <div className="border border-gray-200 rounded-lg pb-20">
            <div className="p-4 border-b border-gray-200 flex items-center justify-between gap-x-4">
              <div>
                <p className="font-semibold text-lg">Email Set up</p>
                <p className="font-bold text-sm text-[#7C8899]">
                  Receive verification codes via email.
                </p>
              </div>
              <button
                className="text-[#A5A5A5] bg-[#F3F4F6] p-2 rounded px-4 cursor-pointer"
                onClick={() => {
                  setType("mail");
                }}
                disabled={loading}
              >
                {user?.is2FAType === "mail"
                  ? `${user?.email.slice(0, 4)}***@${user?.email
                      .split("@")[1]
                      .slice(0, 3)}***.com`
                  : "Not Setup"}{" "}
                <span></span>
              </button>
            </div>
            <div className="p-4 border-b border-gray-200 flex items-center justify-between gap-x-4">
              <div>
                <p className="font-semibold text-lg">Phone Number</p>
                <p className="font-bold text-sm text-[#7C8899]">
                  Receive verification codes via email.
                </p>
              </div>
              <button
                className="text-[#A5A5A5] bg-[#F3F4F6] p-2 rounded px-4 cursor-pointer"
                onClick={() => {
                  toggleModal("phone-auth");
                }}
                disabled={loading}
              >
                Not Setup <span></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <ModalContainer show={isOpen} close={toggleModal} width={"max-w-fit"}>
        <div className="p-6 mt-6">
          {mode === "phone-auth" ? (
            <div>
              <h1 className="text-2xl font-bold">Two-Factor Authentication</h1>
              <p className="text-cs text-[#A5A5A5] font-semibold max-w-sm">
                Enter the mobile phone number for a device you trust. we will
                text you a 6-digit security code to verify your phone
              </p>
              <div className="mt-8 space-y-4">
                <div>
                  <label className="font-semibold text-sm mb-2">
                    Phone Number
                  </label>
                  <div className="mt-2">
                    <PhoneInput
                      placeholder="Enter phone number"
                      value={phone}
                      onChange={setPhone}
                      defaultCountry={"NG"}
                      disabled={user?.telephone ? true : false}
                      className="flex items-center flex-col justify-center outline-none outline-0 border rounded-md h-10 border-gray-200 px-4 w-full placeholder:text-gray-400 placeholder:text-sm"
                    />
                  </div>
                </div>
                <p className="text-[#A5A5A5] font-bold text-xs">
                  Message and data rate may apply
                </p>
              </div>
              <div className="mt-4 flex flex-col px-12">
                <Button
                  loading={loading}
                  text={"Receive Verification Code"}
                  onClick={handlePhone2fa}
                />
              </div>
            </div>
          ) : (
            <div>
              <h1 className="text-2xl font-bold">
                {mode === "email-otp"
                  ? "Verify OTP using email"
                  : "Phone Verification Code"}
              </h1>
              <p className="text-cs text-[#A5A5A5] font-semibold max-w-sm">
                Enter the 6-digit code we sent to{" "}
                {mode === "email-otp" ? "user***@*****" : "+234*****"} to
                confirm your action.{" "}
                {mode === "email-otp"
                  ? " Check spam folder if not received."
                  : " Please chack your SMS message"}
              </p>
              <div className="mt-8 space-y-4">
                <p className="text-sm font-bold">Enter OTP</p>
                <OtpComponent
                  stateData={code}
                  textChange={(data) => {
                    setCode(data);
                  }}
                  css="borderColor"
                  loading={isLoading}
                  numInputs={6}
                />
                <p className="text-[#1E1E1E] font-bold text-md">
                  Resend OTP in 30s.
                </p>
              </div>
              <div className="mt-4 flex flex-col px-12">
                <Button
                  text={"Submit"}
                  handleButton={activateOtp}
                  loading={isLoading}
                />
              </div>
            </div>
          )}
        </div>
      </ModalContainer>
    </div>
  );
};

export default TwoFactorAuth;
