import React, { useState } from "react";
import Nav from "../components/nav/nav";

import { FaLongArrowAltRight } from "react-icons/fa";
// import TestimonialCard from "../components/testimonial-card/testimonial-card";
import Footer from "../components/footer/footer";
import { useNavigate } from "react-router-dom";
import Pricing from "../components/pricing/pricing";
import { useSelector } from "react-redux";
import {
  selectPlans,
  selectProductLoading,
} from "../data/selectors/productSelector";
import { styled } from "@mui/system";
import { Tabs } from "@mui/base/Tabs";
import { TabsList as BaseTabsList } from "@mui/base/TabsList";
import { TabPanel } from "@mui/base/TabPanel";
import { buttonClasses } from "@mui/base/Button";
import { Tab as BaseTab, tabClasses } from "@mui/base/Tab";
import Loader from "../components/loader/loader";
import { motion } from "framer-motion";
import { Zoom } from "../components/animation-frames";

const Home = () => {
  const navigate = useNavigate();

  const plans = useSelector(selectPlans);
  const planLoading = useSelector(selectProductLoading);

  const goTo = (route) => {
    navigate(route);
  };
  return (
    <>
      <header id="home" className="bg-[#2E700E] pb-40 md:pb-96">
        <Nav />
        <div className="text-center">
          <div className="rounded-full w-fit py-2 px-6 mx-auto text-xs text-white bg-[#4CAF50]">
            <span className="">Sales & Inventory Management</span>
          </div>
          <Zoom>
            <motion.article
              className="mt-8"
              initial={{ scale: 0, y: -50 }}
              animate={{ y: 0, scale: 1 }}
            >
              <h1
                className="text-3xl lg:text-5xl font-koho font-black text-white max-w-5xl mx-auto"
                style={{ lineHeight: "120%" }}
              >
                Streamline Your Steel Sales{" "}
                <br className="hidden md:inline-block" />
                <span className="text-[#2E700E] bg-white px-2">
                  And Inventories
                </span>{" "}
                management
              </h1>
              <p className="text-white max-w-xl mx-auto text-xl mt-4">
                The Best and most trusted inventory system. Effortlessly manage
                sales and products in the steel industry
              </p>
            </motion.article>
          </Zoom>
          <div className="my-8">
            <Zoom>
              <button
                className="bg-white h-10 px-8 rounded-full capitalize"
                onClick={() => goTo("/register")}
              >
                Get started
              </button>
            </Zoom>
          </div>
        </div>
      </header>
      <div className="">
        <img
          src={require("../assets/hero.png")}
          alt=""
          className="mx-auto -mt-40 md:-mt-96 shadow-xl"
        />
      </div>
      <section className="max-w-5xl mx-auto p-4 mt-24 font-koho">
        <div className="grid md:grid-cols-2 gap-8 mb-16">
          <div className="self-center order-2 md:order-1">
            <Zoom>
              <h3 className="font-bold text-4xl mb-8">
                Manage your sales and inventories efficiently
              </h3>
              <p className="mb-4 font-semibold text-xl text-[#1E1E1E]">
                Streamlining product cataloging, pricing management, and sales
                reporting for administrators.
              </p>
              <button
                className="bg-black text-white h-10 px-8 rounded-full capitalize"
                onClick={() => goTo("/register")}
              >
                Get started
              </button>
            </Zoom>
          </div>
          <div className="order-1 md:order-2 justify-self-end">
            <img
              src={require("../assets/grid-1.png")}
              alt=""
              className="ma max-h-96"
            />
          </div>
        </div>
      </section>
      <section className="mt-8">
        <div className="container mx-auto p-4">
          <Zoom>
            <h1 className="font-black capitalize text-center text-4xl">
              Simplifying Steel Sales <br className="hidden md:inline-block" />{" "}
              Management
            </h1>
          </Zoom>
          <div className="max-w-5xl mx-auto flex justify-center flex-wrap md:flex-nowrap gap-4 mt-8">
            <div className="p-4 pb-0 bg-[#FFEEDC] rounded-2xl min-w-32 max-w-sm">
              <div className="h-10 w-10 rounded-full bg-[#E4B022] mt-4"></div>
              <article className="mt-4 space-y-2">
                <h3 className="font-bold text-xl">
                  Utilize pricing strategies to optimize{" "}
                  <br className="hidden md:inline-block" /> sales and maximize
                  revenue
                </h3>
                <p className="text-sm font-semibold">
                  Empowering sales managers with tools for efficient sales
                  tracking, dynamic pricing, and performance analysis
                </p>
              </article>
              <div className="mt-8 px-2">
                <img
                  src={require("../assets/flex-1.png")}
                  alt=""
                  className="max-h-80 mx-auto"
                />
              </div>
            </div>
            <div className="p-4 pb-0 bg-[#E4F8F2] rounded-2xl min-w-32 max-w-sm">
              <div className="h-10 w-10 rounded-full bg-[#2E700E] mt-4"></div>
              <article className="mt-4 space-y-2">
                <h3 className="font-bold text-xl">
                  Efficiently organize and manage your{" "}
                  <br className="hidden md:inline-block" /> steel product
                  inventory
                </h3>
                <p className="text-sm font-semibold">
                  Streamlining product cataloging, pricing management, and sales
                  reporting for administrators
                </p>
              </article>
              <div className="mt-8 px-2">
                <img
                  src={require("../assets/flex-2.png")}
                  alt=""
                  className="max-h-80 mx-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about" className="container mx-auto p-4 mt-16">
        <Zoom>
          <h1 className="font-bold text-2xl text-center text-[#7C8899]">
            Join 1,000+ already trusting this app
          </h1>
        </Zoom>
        {/* <div className="mt-8 flex justify-center gap-40 items-center">
          <i>
            <img src={require("../assets/companies/comp-2.png")} alt="" />
          </i>
          <i>
            <img src={require("../assets/companies/comp-3.png")} alt="" />
          </i>
          <i>
            <img src={require("../assets/companies/comp-4.png")} alt="" />
          </i>
          <i>
            <img src={require("../assets/companies/comp-5.png")} alt="" />
          </i>
          <i>
            <img src={require("../assets/companies/comp-6.png")} alt="" />
          </i>
          <i>
            <img src={require("../assets/companies/comp-1.png")} alt="" />
          </i>
        </div> */}
      </section>
      <section className="p-4 py-12 mt-24 bg-[#F3F4F6]">
        <div className="max-w-5xl mx-auto grid md:grid-cols-2 gap-8 mb-16">
          <div className="self-center order-2 md:order-1">
            <h3 className="font-bold text-4xl mb-8">
              Simplified management tools that is easy to us
            </h3>
            <p className="mb-4 font-semibold text-xl text-[#1E1E1E]">
              Our platform offers comprehensive solutions for the steel
              industry. which includes Product cataloging, pricing management,
              and sales reporting.
            </p>
            <div className="flex gap-8">
              <article>
                <h1 className="text-4xl font-black">25K+</h1>
                <p className="text-sm">Satisfied global client</p>
              </article>
              <article>
                <h1 className="text-4xl font-black">79%</h1>
                <p className="text-sm">Revenue Growth </p>
              </article>
            </div>
          </div>
          <div className="order-1 md:order-2 justify-self-end">
            <img
              src={require("../assets/grid-2.png")}
              alt=""
              className="ma max-h-96"
            />
          </div>
        </div>
      </section>
      <section className="container mx-auto p-4 mt-24 font-koho">
        <div className="p-8">
          <Zoom>
            <div className="md:max-w-md text-center mx-auto mb-8">
              <h2 className="text-4xl font-bold">
                Choose the Plan That Fits <br /> Your Needs
              </h2>
              <p className="md:text-sm font-semibold">
                Enjoy the available feature from any of our plans.{" "}
                <br className="hidden md:inline-block" /> we are her to serve
                you better
              </p>
            </div>
          </Zoom>
          {planLoading ? (
            <div className="flex items-center justify-center">
              <Loader />
            </div>
          ) : (
            plans && (
              <Tabs defaultValue={1}>
                <TabsList>
                  <Tab value={1}>Month</Tab>
                  <Tab value={2}>Year</Tab>
                </TabsList>
                {/* <Zoom> */}
                  <div className="flex justify-center gap-6 mt-8 flex-wrap">
                    {plans?.docs?.map((plan, id) =>
                      plan?.frequency === "month" ? (
                        <TabPanel value={1}>
                          <Pricing key={id} plan={plan} />
                        </TabPanel>
                      ) : (
                        <TabPanel value={2}>
                          <Pricing key={id} plan={plan} />
                        </TabPanel>
                      )
                    )}
                  </div>
                {/* </Zoom> */}
              </Tabs>
            )
          )}
          {/* <div className="flex justify-center gap-6 mt-8 flex-wrap">
            <Pricing />
            <Pricing />
            <Pricing />
          </div> */}
        </div>
      </section>
      <section className="p-8 py-12 mt-24 bg-[#F3F4F6]">
        <div className="bg-[#2E700E] p-8 rounded-xl max-w-4xl mx-auto">
          <h1 className="text-4xl md:text-5xl text-center font-black text-white">
            Ready to get started?
          </h1>
          <p className="font-light text-xl text-white mt-8 text-center max-w-lg mx-auto">
            Enjoy a comprehensive management solutions for sales and inventories
            of steel industry.
          </p>
          <div className="flex justify-center mt-12">
            <button
              className="bg-white h-12 px-8 rounded-full capitalize"
              onClick={() => goTo("/register")}
            >
              Get Started
            </button>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Home;

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

// &:hover {
//   background-color: ${blue[400]};
// }

const Tab = styled(BaseTab)`
  color: #fff;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: transparent;
  width: 100%;
  padding: 10px 12px;
  margin: 6px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;

  &:focus {
    color: #fff;
    outline: 3px solid ${grey[800]};
  }

  &.${tabClasses.selected} {
    background-color: #fff;
    color: black;
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabsList = styled(BaseTabsList)(
  ({ theme }) => `
  max-width: 150px;
  background-color: ${grey[300]};
  border-radius: 12px;
  margin: auto;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  box-shadow: 0px 4px 30px ${
    theme.palette.mode === "dark" ? grey[900] : grey[200]
  };
  `
);
