import React from "react";

const Avatar = ({ name, title, image }) => {
  return (
    <div className="flex items-center gap-4">
      <img
        className="w-10 h-10 rounded-md"
        src={image || require("../../assets/Rectangle 195.png")}
        alt=""
      />
      <div className="">
        <div>{name || "Product Name"}</div>
        {title && (
          <div className="text-xs text-gray-500">
            {title || "Product title"}
          </div>
        )}
      </div>
    </div>
  );
};

export default Avatar;
