import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  currency: {},
  rates: [],
  currencyConfig: {},
  error: null,
  rateError: null,
  configError: null,
  rateLoading: false,
  configLoading: false,
  loading: false,
};

const CurrencySlice = createSlice({
  name: "content",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchCurrency.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchCurrency.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.currency = payload.data;
    });
    builder.addCase(fetchCurrency.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error;
    });
    builder.addCase(fetchRates.pending, (state) => {
      state.rateLoading = true;
      state.rateError = null;
    });
    builder.addCase(fetchRates.fulfilled, (state, { payload }) => {
      state.rateLoading = false;
      state.rates = payload.data;
    });
    builder.addCase(fetchRates.rejected, (state, { error }) => {
      state.rateLoading = false;
      state.rateError = error;
    });
    builder.addCase(fetchCurrencyConfig.pending, (state) => {
      state.configError = null;
      state.configLoading = true;
    });
    builder.addCase(fetchCurrencyConfig.fulfilled, (state, { payload }) => {
      state.configLoading = false;
      state.currencyConfig = payload.data;
    });
    builder.addCase(fetchCurrencyConfig.rejected, (state, { error }) => {
      state.configLoading = false;
      state.configError = error;
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = CurrencySlice.actions;

export default CurrencySlice.reducer;

export const fetchCurrency = createAsyncThunk(
  "currency/fetchCurrency",
  async (_, thunkAPI) => {
    try {
      const res = await axios.get(`api/v1/exchange/currency`);
      return res.data;
    } catch (err) {
      console.log({ fetchCurrency: err });
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const fetchRates = createAsyncThunk(
  "currency/fetchRates",
  async (_, thunkAPI) => {
    try {
      const res = await axios.get(`api/exchange/rate`);
      return res.data;
    } catch (err) {
      console.log({ fetchRates: err });
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const fetchCurrencyConfig = createAsyncThunk(
  "currency/fetchCurrencyConfig",
  async (company, thunkAPI) => {
    try {
      const res = await axios.get(`api/v1/exchange/user-configuration`, {
        headers: {
          "company-id": company,
        },
      });
      return res.data;
    } catch (err) {
      console.log({ fetchCurrencyConfig: err });
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);
