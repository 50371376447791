import React, { useState } from "react";
import Button from "../../components/button/button";
import { MdAdd } from "react-icons/md";
import { BiSearch } from "react-icons/bi";
import { MdOutlineEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader/loader";
import NoData from "../../components/no-data/nodata";
import { useSelector, useDispatch } from "react-redux";
import {
  selectActiveCompany,
  selectCompanies,
  selectCompanyLoading,
} from "../../data/selectors/companySelector";
import { useEffect } from "react";
import {
  fetchAllCompanies,
  fetchCompanies,
  updateCompany,
} from "../../data/Reducers/companySlice";
import { Toggle } from "../../components/input/input";
import ModalContainer from "../../components/modal-container/modal-container";
import { toast } from "react-toastify";
import axios from "axios";
import { handleError } from "../../screens/login";

const ManageCompany = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState();
  const [companyId, setCompanyId] = useState("");
  const companies = useSelector(selectCompanies);
  const companyLoading = useSelector(selectCompanyLoading);
  const activeCompany = useSelector(selectActiveCompany);

  const setActiveCompany = (company) => {
    dispatch(updateCompany(company));
  };

  const toggleDeleteModal = (id) => {
    setCompanyId(id);
    setDeleteModal(!deleteModal);
  };

  const handleDeleteCompany = async () => {
    setLoading(true);
    try {
      const res = await axios.delete(
        `api/v1/user/manage-company?company=${companyId}`
      );
      setLoading(false);
      toast.success(res.data.message);
      localStorage.removeItem("activeCompany");
      dispatch(fetchCompanies());
      dispatch(fetchCompanies());
      dispatch(fetchAllCompanies());
      toggleDeleteModal();
    } catch (err) {
      setLoading(false);
      console.log(err);
      // toast.error(err.response.data.message);
      handleError(err, toast);
    }
  };

  useEffect(() => {
    dispatch(fetchAllCompanies());
    dispatch(fetchCompanies());
  }, []);

  useEffect(() => {}, []);

  return (
    <div className="p-4 overflow-auto no-scrollbar">
      <div className="flex items-center justify-between gap-4 flex-wrap">
        <h1 className="text-2xl font-bold">Manage Company</h1>
        <div className="flex items-center gap-4">
          <Button
            text={"Add Company"}
            icon={<MdAdd />}
            handleButton={() => navigate("add-company")}
          />
        </div>
      </div>
      <div className="bg-white rounded-xl mt-4">
        <div className="flex items-center justify-between border-b border-gray-200 p-4">
          <h2 className="font-semibold text-lg">Report Generate</h2>
          <div className="flex items-center gap-4">
            <div className="search flex items-center w-fit px-4 rounded-md border">
              <input
                type="text"
                placeholder="Company name"
                className="md:w-80 outline-none bg-transparent border-none font-light text-sm h-10 px-2"
              />
              <span className="text-gray-300">
                <BiSearch />
              </span>
            </div>
          </div>
        </div>
        <div className="overflow-x-auto no-scrollbar pb-20">
          <table className="w-full">
            <colgroup>
              <col span={6} />
            </colgroup>
            <thead>
              <tr className="border-b border-gray-200">
                <td className="p-2 capitalize font-semibold text-sm">
                  <p className="mb-2">Company name</p>
                </td>
                <td className="p-2 capitalize font-semibold text-sm">
                  <p className="mb-2">Email Address</p>
                </td>
                <td className="p-2 capitalize font-semibold text-sm">
                  <p className="mb-2">Status</p>
                </td>
                <td className="p-2 capitalize font-semibold text-sm">
                  <p className="mb-2">Action</p>
                </td>
              </tr>
            </thead>
            <tbody className="relative">
              {companyLoading ? (
                <tr>
                  <td colSpan={5}>
                    <div className="flex justify-center w-full py-8">
                      <Loader />
                    </div>
                  </td>
                </tr>
              ) : !companies.docs?.length ? (
                <tr>
                  <td colSpan={5}>
                    <div className="flex justify-center w-full py-8">
                      <NoData />
                    </div>
                  </td>
                </tr>
              ) : (
                companies.docs?.map((company) => (
                  <tr className="border-b border-gray-200 font-semibold text-sm whitespace-nowrap">
                    <td className="col p-2">{company.name}</td>
                    <td className="col p-2">{company.email}</td>
                    <td className="col p-2">
                      {/* <div
                        className={`${
                          company._id === activeCompany._id
                            ? "text-[#2ED8B6] bg-[#2ED8B61A]"
                            : "text-[#ca3939] bg-[#ca39394b]"
                        }  py-1 px-6 w-fit rounded-full`}
                      >
                        {company._id === activeCompany._id
                          ? "Active"
                          : "Disabled"}
                      </div> */}
                      <Toggle
                        checked={
                          activeCompany && company?._id === activeCompany?._id
                        }
                        onClick={() => setActiveCompany(company)}
                      />
                    </td>
                    <td className="col p-2">
                      <div className="flex items-center gap-4 text-lg">
                        <span
                          className="p cursor-pointer"
                          onClick={() =>
                            navigate("add-company", { state: company })
                          }
                        >
                          <MdOutlineEdit />
                        </span>
                        <span
                          className="p cursor-pointer"
                          onClick={() => toggleDeleteModal(company?._id)}
                        >
                          <RiDeleteBin6Line />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>

      <ModalContainer
        width={"max-w-sm"}
        show={deleteModal}
        close={toggleDeleteModal}
      >
        <div className="p-12">
          <form className="space-y-4">
            <div className="w-100">
              <p className="text-2xl text-center">
                Are you sure you want to Delete Company?
              </p>
              <div className="pt-4 flex items-center justify-center gap-8">
                <Button
                  type="button"
                  text={"Yes"}
                  loading={loading}
                  handleButton={handleDeleteCompany}
                />
                <button
                  className="h-10 rounded-lg border border-black font-semibold text-xs px-8"
                  type="button"
                  onClick={toggleDeleteModal}
                >
                  No
                </button>
              </div>
            </div>
          </form>
        </div>
      </ModalContainer>
    </div>
  );
};

export default ManageCompany;
