import React from "react";
import Button from "../../components/button/button";
import { MdAdd } from "react-icons/md";
import { BsFileEarmarkText } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Input, { RadioButton, Toggle } from "../../components/input/input";
import { useSelector, useDispatch } from "react-redux";
import {
  selectProducts,
  selectSubProducts,
  selectSubProductsLoading,
} from "../../data/selectors/productSelector";
import { selectActiveCompany } from "../../data/selectors/companySelector";
import { useEffect } from "react";
import { toast } from "react-toastify";
import {
  fetchAllProducts,
  fetchAllSubProducts,
} from "../../data/Reducers/productReducer";
import { useState } from "react";
import moment from "moment";
import { selectSurchargePeriods } from "../../data/selectors/surchargeSelector";
import {
  fetchSurchargePeriods,
  getAverageOfMonths,
  getPeriodsForSubproducts,
} from "../../data/Reducers/surcharge";
import axios, { all } from "axios";
import Loader from "../../components/loader/loader";
import { handleError } from "../../screens/login";
import AccordionGroup from "@mui/joy/AccordionGroup";
import Accordion from "@mui/joy/Accordion";
import AccordionDetails from "@mui/joy/AccordionDetails";
import AccordionSummary from "@mui/joy/AccordionSummary";

const Report = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [index, setIndex] = React.useState(0);
  const products = useSelector(selectProducts);
  const company = useSelector(selectActiveCompany);
  const subProducts = useSelector(selectSubProducts);
  const subProductLoading = useSelector(selectSubProductsLoading);
  const periods = useSelector(selectSurchargePeriods);
  const [loading, setLoading] = useState(false);

  const [subPeriods, setSubPeriods] = useState();

  const [range, setRange] = useState({});

  const [radio, setRadio] = useState({
    currency: "usd",
    unit: "lbs",
  });
  const handleUnit = (e) => {
    const { checked, name, value } = e.target;
    setRadio({ ...radio, [name]: value });
  };

  const handleSelectPeriods = (e, subProduct, mode) => {
    const { name, value } = e.target;
    let start = range[name]?.["start"];
    let end = range[name]?.["end"];
    if (mode !== "start" && !start)
      return toast.error("Select start date first");
    setRange({ ...range, [name]: { ...range[name], [mode]: value } });
  };

  const [selectedProduct, setSelectedProduct] = useState();

  const handleGenerateReport = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await Promise.all(
        Object.entries(range).map(([product, range]) =>
          axios.post(
            `/api/v1/product/report?product=${selectedProduct._id}&measure=${radio.unit}`,
            {
              subcomponent: [
                {
                  subproduct: product,
                  startTimeFrame: range.start.split("T")[0],
                  endTimeFrame: range.end.split("T")[0],
                },
              ],
            },
            {
              headers: {
                "company-id": company._id,
              },
            }
          )
        )
      );
      let allRates = {};
      let avgRate;
      if (res) {
        const rates = await Promise.all(
          Object.entries(range).map(([product, range]) =>
            axios.get(
              `api/v1/exchange/rate?denomination=${"EUR"}&exchangeDenomination=${
                radio.currency
              }&startTimeFrame=${range.start.split("T")[0]}&endTimeFrame=${
                range.end.split("T")[0]
              }`,
              {
                headers: {
                  "company-id": company._id,
                },
              }
            )
          )
        );
        let myRates = await rates.map((rate) => rate.data?.data);
        myRates = myRates.map((rates) => {
          let rate = rates.map((rate) => {
            let count = 0;
            Object.entries(rate).forEach(([k, v]) => {
              if (k === "value") {
                count += v;
              }
            });
            return count;
          });
          let avg = rate.reduce((res, curr) => res + curr, 0);
          return avg / rate.length;
        });
        avgRate = myRates.reduce((res, curr) => res + curr, 0) / myRates.length;
        Object.entries(range).forEach(
          ([product, range], i) => (allRates[product] = myRates[i])
        );
      }
      console.log({ allRates });
      console.log({ avgRate });
      const data = await res[0].data?.data?.docs;
      const report = data.find((item) => item._id === selectedProduct);
      navigate(report._id, { state: { report, radio, allRates, avgRate } });
    } catch (error) {
      console.log({ error });
      handleError(error, toast);
    }
    setLoading(false);
  };

  useEffect(() => {
    // if (!products.length) {
    dispatch(fetchAllProducts(company?._id));
    dispatch(fetchSurchargePeriods());
    // }
  }, []);
  useEffect(() => {
    if (selectedProduct) {
      dispatch(
        fetchAllSubProducts({
          companyId: company?._id,
          productId: selectedProduct,
        })
      );
    }
  }, [selectedProduct]);
  useEffect(() => {
    setSubPeriods(getPeriodsForSubproducts(subProducts, periods));
  }, [subProducts, selectProducts]);
  return (
    <div className="p-4 overflow-auto min-h-screen no-scrollbar">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold">Report</h1>
      </div>
      <div className="bg-white rounded-xl mt-4 h-full">
        <div className="flex items-center justify-between border-b border-gray-200 p-4">
          <h2 className="font-semibold text-lg">Report Generate</h2>
          <div className="flex items-center gap-4"></div>
        </div>
        <div className="p-4 max-w-4xl">
          <div className="border border-gray-200 rounded-lg overflow-auto h-full">
            <div className="p-4 border-b border-gray-200 flex items-center justify-between gap-x-4">
              <p className="font-semibold text-lg">Generate New Report</p>
              <Button text={"Export"} icon={<BsFileEarmarkText />} />
            </div>
            <div className="p-4 h-full overflow-y-auto">
              <form onSubmit={handleGenerateReport}>
                <AccordionGroup sx={{}}>
                  <Accordion
                    expanded={index === 0}
                    onChange={(event, expanded) => {
                      setIndex(expanded ? 0 : null);
                    }}
                  >
                    <AccordionSummary sx={{ padding: "16px 0" }}>
                      <label htmlFor="" className="text-2xl font-bold">
                        Step 1:{" "}
                        <span className="font-medium">Select your product</span>
                      </label>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: "16px 0" }}>
                      <div className="max-w-xs">
                        <Input
                          type={"select"}
                          selectHolder={"Choose product"}
                          options={products?.docs}
                          onChange={(e) => setSelectedProduct(e.target.value)}
                        />
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={index === 1}
                    onChange={(event, expanded) => {
                      setIndex(expanded ? 1 : null);
                    }}
                  >
                    <AccordionSummary sx={{ padding: "16px 0" }}>
                      <label htmlFor="" className="text-2xl font-bold">
                        Step 2:{" "}
                        <span className="font-medium">
                          Select currency & unit
                        </span>
                      </label>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: "16px 0" }}>
                      <div className="grid grid-cols-2">
                        <div className="m col-span-2 text-lg font-semibold mt-2">
                          Currency
                        </div>
                        <div className="flex items-center gap-4">
                          <RadioButton
                            label={"Euro"}
                            name={"currency"}
                            value={"eur"}
                            checked={radio.currency === "eur"}
                            onChange={handleUnit}
                          />
                          <RadioButton
                            label={"Dollar"}
                            name={"currency"}
                            value={"usd"}
                            checked={radio.currency === "usd"}
                            onChange={handleUnit}
                          />
                        </div>

                        <div className="m col-span-2 text-lg font-semibold mt-2">
                          Select Unit of measure
                        </div>
                        <div className="flex items-center gap-4">
                          <RadioButton
                            label={"lbs"}
                            name={"unit"}
                            value={"lbs"}
                            onChange={handleUnit}
                            checked={radio.unit === "lbs"}
                          />
                          <RadioButton
                            label={"kg"}
                            name={"unit"}
                            value={"kg"}
                            onChange={handleUnit}
                            checked={radio.unit == "kg"}
                          />
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={index === 2}
                    onChange={(event, expanded) => {
                      setIndex(expanded ? 2 : null);
                    }}
                  >
                    <AccordionSummary sx={{ padding: "16px 0" }}>
                      <label htmlFor="" className="text-2xl font-bold">
                        Step 3:{" "}
                        <span className="font-medium">Select time period</span>
                      </label>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: "16px 0" }}>
                      {subProductLoading ? (
                        <div className="p-8 flex justify-center">
                          <Loader />
                        </div>
                      ) : (
                        subProducts &&
                        subPeriods?.map((product, idx) => (
                          <div className="grid grid-cols-3" key={idx}>
                            <div className="n self-center text-lg font-medium">
                              {product?.subProduct?.title}
                            </div>
                            <div className="flex items-center gap-3 w-full col-span-2">
                              <Input
                                type={"select"}
                                options={product.periods}
                                selectHolder={"Start"}
                                onChange={(e) =>
                                  handleSelectPeriods(
                                    e,
                                    product.subProduct,
                                    "start"
                                  )
                                }
                                name={product.subProduct._id}
                              />
                              <span>to</span>
                              <Input
                                type={"select"}
                                options={product.periods}
                                selectHolder={"End"}
                                onChange={(e) =>
                                  handleSelectPeriods(
                                    e,
                                    product.subProduct,
                                    "end"
                                  )
                                }
                                name={product.subProduct._id}
                              />
                            </div>
                            <div>{product.average || ""}</div>
                          </div>
                        ))
                      )}
                    </AccordionDetails>
                  </Accordion>
                </AccordionGroup>
                <div className="space-y-8">
                  {/* <article>
                    <label htmlFor="" className="text-2xl font-bold">
                      Step 1:{" "}
                      <span className="font-medium">Select your product</span>
                    </label>
                    <div className="max-w-xs">
                      <Input
                        type={"select"}
                        selectHolder={"Choose product"}
                        options={products?.docs}
                        onChange={(e) => setSelectedProduct(e.target.value)}
                      />
                    </div>
                  </article>
                  <article>
                    <label htmlFor="" className="text-2xl font-bold">
                      Step 2:{" "}
                      <span className="font-medium">
                        Select currency & unit
                      </span>
                    </label>
                    <div className="grid grid-cols-2">
                      <div className="m col-span-2 text-lg font-semibold mt-2">
                        Currency
                      </div>
                      <div className="flex items-center gap-4">
                        <RadioButton
                          label={"Euro"}
                          name={"currency"}
                          value={"eur"}
                          checked={radio.currency === "eur"}
                          onChange={handleUnit}
                        />
                        <RadioButton
                          label={"Dollar"}
                          name={"currency"}
                          value={"usd"}
                          checked={radio.currency === "usd"}
                          onChange={handleUnit}
                        />
                      </div>

                      <div className="m col-span-2 text-lg font-semibold mt-2">
                        Select Unit of measure
                      </div>
                      <div className="flex items-center gap-4">
                        <RadioButton
                          label={"lbs"}
                          name={"unit"}
                          value={"lbs"}
                          onChange={handleUnit}
                          checked={radio.unit === "lbs"}
                        />
                        <RadioButton
                          label={"kg"}
                          name={"unit"}
                          value={"kg"}
                          onChange={handleUnit}
                          checked={radio.unit == "kg"}
                        />
                      </div>
                    </div>
                  </article>
                  <article>
                    <label htmlFor="" className="text-2xl font-bold">
                      Step 3:{" "}
                      <span className="font-medium">Select time period</span>
                    </label>
                    {subProductLoading ? (
                      <div className="p-8 flex justify-center">
                        <Loader />
                      </div>
                    ) : (
                      subProducts &&
                      subPeriods?.map((product, idx) => (
                        <div className="grid grid-cols-3" key={idx}>
                          <div className="n self-center text-lg font-medium">
                            {product?.subProduct?.title}
                          </div>
                          <div className="flex items-center gap-3 w-full col-span-2">
                            <Input
                              type={"select"}
                              options={product.periods}
                              selectHolder={"Start"}
                              onChange={(e) =>
                                handleSelectPeriods(
                                  e,
                                  product.subProduct,
                                  "start"
                                )
                              }
                              name={product.subProduct._id}
                            />
                            <span>to</span>
                            <Input
                              type={"select"}
                              options={product.periods}
                              selectHolder={"End"}
                              onChange={(e) =>
                                handleSelectPeriods(
                                  e,
                                  product.subProduct,
                                  "end"
                                )
                              }
                              name={product.subProduct._id}
                            />
                          </div>
                          <div>{product.average || ""}</div>
                        </div>
                      ))
                    )}
                  </article> */}
                  <div className="max-w-xs mx-auto">
                    {!selectedProduct ? (
                      <Button
                        text={"Generate Report"}
                        disabled={true}
                        style={"w-full"}
                      />
                    ) : (
                      <Button
                        text={"Generate Report"}
                        loading={loading}
                        style={"w-full"}
                      />
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Report;
