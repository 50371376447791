import React from "react";

import { FaFacebookF } from "react-icons/fa";

const FootIcon = ({ icon }) => {
  return (
    <div className="text-white text-2xl bg-[#2E700E] p-2 rounded-full h-6 w-6 flex items-center justify-center">
      {icon || <FaFacebookF />}
    </div>
  );
};

export default FootIcon;
