import React from "react";
import { MdTrendingUp } from "react-icons/md";

const DashboardCard = ({ title, amount, text, color }) => {
  return (
    <div className="bg-white rounded-md p-4 h-36 flex flex-col justify-between">
      <p className="font-medium text-sm">{title}</p>
      <h3 className="text-2xl font-extrabold">{amount}</h3>
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-1 text-xs font-medium whitespace-nowrap" style={{ color }}>
          <div className="relative flex items-center gap-1 p-1 px-2">
            <div
              className="absolute inset-0 rounded-full"
              style={{ background: color, opacity: "20%" }}
            ></div>
            <div className="relative flex items-center gap-1 font-semibold">
              <span>
                <MdTrendingUp />
              </span>
              +0,7%
            </div>
          </div>
          <span>vs {text}</span>
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;
