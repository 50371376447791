import { createSelector } from "@reduxjs/toolkit";

const surchargeSlice = (state) => state.surcharge;

export const selectSurchargeProducts = createSelector(
  [surchargeSlice],
  (surcharge) => surcharge.products
);

export const selectSurchargeProductLoading = createSelector(
  [surchargeSlice],
  (loading) => loading.productLoading
);

export const selectSurchargeTypes = createSelector(
  [surchargeSlice],
  (surcharge) => surcharge.types
);

export const selectSurchargeTypesLoading = createSelector(
  [surchargeSlice],
  (loading) => loading.typeLoading
);

export const selectSurchargeRanges = createSelector(
  [surchargeSlice],
  (surcharge) => surcharge.ranges
);

export const selectSurchargeRangeLoading = createSelector(
  [surchargeSlice],
  (loading) => loading.rangeLoading
);

export const selectSurchargeSubRanges = createSelector(
  [surchargeSlice],
  (surcharge) => surcharge.subRanges
);

export const selectSurchargeSubRangeLoading = createSelector(
  [surchargeSlice],
  (loading) => loading.subRangeLoading
);
export const selectSurchargeSubRangesById = createSelector(
  [surchargeSlice],
  (surcharge) => surcharge.subRangesById
);

export const selectSurchargeSubRangeByIdLoading = createSelector(
  [surchargeSlice],
  (loading) => loading.subRangeByIdLoading
);

export const selectSurchargePeriods = createSelector(
  [surchargeSlice],
  (surcharge) => surcharge.periods
);

export const selectSurchargePeriodLoading = createSelector(
  [surchargeSlice],
  (loading) => loading.periodLoading
);
