import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Brand from "../components/brand/brand";
import { ReactComponent as Google } from "../assets/svg/google.svg";
import Input from "../components/input/input";
import Button from "../components/button/button";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "axios";
import { ReuseBox } from "./register";
import { handleError } from "./login";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });


  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const res = await axios.put("api/v1/user/reset-password", data);
      setLoading(false);
      toast.success(res.data.message);
      navigate("/reset-password");
    } catch (err) {
      console.log(err);
      toast.error(err.response.data?.message);
      setLoading(false);
      handleError(err, toast)
    }
  };
  return (
    <div>
      <ReuseBox>
        <div className="flex h-full items-center flex-col justify-center">
          <div className="text-center max-w-xl mx-auto my-4">
            <h1 className="font-semibold text-2xl">Forgot Password</h1>
            <p className="text-sm">
              Enter your email and ww will send you a link
            </p>
          </div>
          <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
            <div className="grid sm:grid-cols-2 gap-4">
              <div className="col-span-2">
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: true,
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Invalid email format",
                    },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <Input
                      placeholder="Email address"
                      type={"email"}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                {errors.email && (
                  <p className="text-red-500 text-xs">
                    This field is required.
                  </p>
                )}
              </div>
            </div>
            <div className="mt-8">
              <Button
                text={"Send me the link"}
                style={"w-full"}
                loading={loading}
              ></Button>
            </div>
          </form>
        </div>
      </ReuseBox>
    </div>
  );
};

export default ForgotPassword;
