import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  fetchCardStats,
  fetchIndicatorStats,
  fetchRevenueStats,
  fetchSalesStats,
} from "./dashboard";

const initialState = {
  allCompanies: [],
  companies: [],
  activeCompany: {},
  loading: false,
  error: null,
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setAllCompanies: (state, { payload }) => {
      state.allCompanies = payload.data;
    },
    setActiveCompany: (state, { payload }) => {
      state.activeCompany = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllCompanies.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchAllCompanies.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.companies = payload.data;
    });
    builder.addCase(fetchAllCompanies.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error;
    });
    builder.addCase(fetchCompanies.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchCompanies.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.allCompanies = payload.data;
    });
    builder.addCase(fetchCompanies.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error;
    });
  },
});

export const { setAllCompanies, setActiveCompany } = companySlice.actions;
export default companySlice.reducer;

export const fetchAllCompanies = createAsyncThunk(
  "company/fetchAllCompanies",
  async (_, thunkAPI) => {
    try {
      const res = await axios.get("api/v1/user/manage-company");
      return res.data;
    } catch (err) {
      console.log({ fetchAllCompaniesErr: err });
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const fetchCompanies = createAsyncThunk(
  "company/fetchCompanies",
  async (_, thunkAPI) => {
    try {
      const res = await axios.get("api/v1/user/manage-company?pagination=not");
      const data = await res.data.data.docs[0];
      let company = localStorage.getItem("activeCompany");
      if (!company || company === "undefined") {
        company = data;
      } else {
        company = JSON.parse(company);
      }
      thunkAPI.dispatch(updateCompany(company));
      return res.data;
    } catch (err) {
      console.log({ fetchCompaniesErr: err });
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const updateCompany = (company) => async (dispatch) => {
  localStorage.setItem("activeCompany", JSON.stringify(company));
  dispatch(setActiveCompany(company));
  dispatch(fetchIndicatorStats(company?._id));
  dispatch(fetchSalesStats(company?._id));
  dispatch(fetchCardStats(company?._id));
  dispatch(fetchRevenueStats(company?._id));
};

// export const fetchCompanies = () => async (dispatch) => {
//   const res = await axios.get("api/v1/user/manage-company?pagination=not");
//   console.log(res);
//   dispatch(setAllCompanies(res.data));
// };
