import React from "react";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";

const Button = ({ text, style, handleButton, loading, icon, ...restProps }) => {
  const navigate = useNavigate();
  return (
    <button
      className={`capitalize font-semibold whitespace-nowrap h-10 px-8 rounded-md font-karla text-sm bg-gradient-to-r from-[#BDBDBD] via-[#E0E0E0] to-[#BDBDBD] ${style}`}
      onClick={handleButton}
      disabled={loading}
      {...restProps}
    >
      {loading ? (
        <span className="text-white flex items-center justify-center w-20 mx-auto">
          <ClipLoader size={20} color="#fff" />
        </span>
      ) : (
        <span className="flex justify-center items-center gap-2 text-center">
          {icon && <span>{icon}</span>}
          {text}
        </span>
      )}
    </button>
  );
};

export default Button;
