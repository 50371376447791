import { createSelector } from "@reduxjs/toolkit";

const companySlice = (state) => state.company;

export const selectCompanies = createSelector(
  [companySlice],
  (company) => company.companies
);

export const selectAllCompanies = createSelector(
  [companySlice],
  (company) => company.allCompanies
);

export const selectCompanyLoading = createSelector(
  [companySlice],
  (loading) => loading.loading
);

export const selectActiveCompany = createSelector(
  [companySlice],
  (company) => company.activeCompany
);
