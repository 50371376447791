import React, { useContext } from "react";
import Button from "../../components/button/button";
import { MdAdd } from "react-icons/md";
import { SlCalculator } from "react-icons/sl";
import { FaArrowUpWideShort } from "react-icons/fa6";
import DashboardCard from "../../components/dashboard-card/dashboard-card";
import RadialBar from "../../components/radial-bar/radialbar";
import ColumnBar from "../../components/column-bar/column-bar";
import Avatar from "../../components/avatar/avatar";
import LineChartGraph from "../../components/line-chart/line-chart";
import { useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import {
  selectCardStats,
  selectInicatorStats,
  selectRevenueStats,
  selectSalesStats,
} from "../../data/selectors/dashboardSelector";
import { useNavigate } from "react-router-dom";
import { GlobalState } from "../../data/Context";

const Dashboard = () => {
  const { numberWithCommas } = useContext(GlobalState);
  const cardStats = useSelector(selectCardStats);
  const sales = useSelector(selectSalesStats);
  const navigate = useNavigate();
  const revenue = useSelector(selectRevenueStats);
  const indicator = useSelector(selectInicatorStats);

  return (
    <div className="p-4 overflow-auto no-scrollbar">
      <div className="flex justify-between whitespace-nowrap gap-6 flex-wrap">
        <div>
          <h1 className="text-xl md:text-3xl font-bold">Dashboard SRM</h1>
          <p className="text-sm font-medium">
            Total performance for this month
          </p>
        </div>
        <div className="flex gap-2 flex-wrap">
          <Button
            text={"Add Product"}
            icon={<MdAdd />}
            handleButton={() => navigate("/products")}
          />
          <Button
            text={"Calculator"}
            icon={<SlCalculator />}
            handleButton={() => navigate("/report")}
          />
        </div>
      </div>
      <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-4 mt-8">
        <DashboardCard
          title={"Total Sales"}
          amount={cardStats?.sales && "$" + numberWithCommas(cardStats?.sales)}
          text={"Target Sales"}
          color={"#3B76EF"}
        />
        <DashboardCard
          title={"Total Profit"}
          amount={
            cardStats?.profit && "$" + numberWithCommas(cardStats?.profit)
          }
          text={"Target Profit"}
          color={"#FFB64D"}
        />
        <DashboardCard
          title={"Total order"}
          amount={cardStats?.order && numberWithCommas(cardStats?.order)}
          text={"Target Order"}
          color={"#DC3545"}
        />
        <DashboardCard
          title={"New Order"}
          amount={cardStats?.newOrder && numberWithCommas(cardStats?.newOrder)}
          text={"Previous Order"}
          color={"#2ED8B6"}
        />
      </div>
      <div className="grid md:grid-cols-2 mt-8 gap-4">
        <div className="bg-white rounded p-4 md:col-span-2">
          <div className="flex justify-between mb-4 items-center gap-6 flex-wrap w-full overflow-scroll scrollbar-hide">
            <div>
              <p className="font-bold">Total Sales</p>
              <p className="text-2xl font-bold">
                ${cardStats?.sales && numberWithCommas(cardStats?.sales)}
              </p>
            </div>
          </div>
          <div className="w-full">
            <ColumnBar
              dataset={sales}
              dataKeyY={"count"}
              dataKeyX={"monthName"}
            />
          </div>
        </div>
        <div className="bg-white rounded-lg p-4">
          <div className="flex justify-between mb-4 items-center gap-6 flex-wrap">
            <div>
              <p className="font-bold">Total Revenue</p>
            </div>
            <div className="flex gap-4 flex-wrap"></div>
          </div>
          <div className="flex justify-center">
            {revenue && (
              <LineChartGraph
                series={[
                  {
                    data: [
                      "Jan",
                      "Feb",
                      "Mar",
                      "Apr",
                      "May",
                      "Jun",
                      "Jul",
                      "Aug",
                      "Sep",
                      "Oct",
                      "Nov",
                      "Dec",
                    ].map((month, i) =>
                      month === revenue[i]?.monthName ? revenue[i]?.count : 0
                    ),
                    area: true,
                    color: "#2E700E",
                  },
                ]}
                labelX={"Revenue generated over time."}
                // labelY={"Amount"}
              />
            )}
          </div>
        </div>
        <div className="bg-white rounded-lg p-4">
          <div className="flex justify-between mb-4 items-center gap-6 flex-wrap">
            <div>
              <p className="font-bold">Product Indicator</p>
            </div>
          </div>
          <div className="space-y-8">
            {indicator &&
              indicator.map((ind) => (
                <div className="flex justify-between gap-2">
                  <div className="w-full">
                    <p className="font-medium text-lg">
                      {ind.title || "Product name"}
                    </p>
                    <LinearProgress
                      variant="determinate"
                      value={
                        (ind?.data /
                          10 ** Math.floor(ind?.data).toString().length) *
                          100 || 70
                      }
                      sx={{ padding: 1 / 2, borderRadius: 1 }}
                    />
                  </div>
                  <p className="text-lg">
                    {(ind?.data && numberWithCommas(ind?.data)) || "$45K"}
                  </p>
                </div>
              ))}
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default Dashboard;
