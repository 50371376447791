import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  content: {},
  contentCategories: [],
  faq: {},
  loading: false,
  error: null,
};

const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {
    setCategories: (state, { payload }) => {
      state.contentCategories = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchContents.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchContents.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.content = payload.data;
    });
    builder.addCase(fetchContents.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error;
    });
    builder.addCase(fetchFaq.pending, (state) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchFaq.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.faq = payload.data;
    });
    builder.addCase(fetchFaq.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setCategories } = contentSlice.actions;

export default contentSlice.reducer;

export const fetchContents = createAsyncThunk(
  "content/fetchContents",
  async (_, thunkAPI) => {
    try {
      const res = await axios.get("api/v1/article");
      return res.data;
    } catch (err) {
      console.log({ fetchContents: err });
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);
export const fetchFaq = createAsyncThunk(
  "content/fetchFaq",
  async (_, thunkAPI) => {
    try {
      const res = await axios.get("api/v1/faq");
      return res.data;
    } catch (err) {
      console.log({ fetchFaq: err });
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const getContentCategories = () => async (dispatch) => {
  try {
    const res = await axios.get("api/v1/article?pagination=not");
    const contents = res.data.data.docs;
    const categories = contents.map((content) => {
      return content.category;
    });
    const unique = [...new Set(categories)];
    dispatch(setCategories(unique));
  } catch (err) {
    console.log({ getContentCategories: err });
  }
};
