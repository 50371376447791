import React from "react";
import { useNavigate } from "react-router-dom";
import Brand from "../components/brand/brand";
import { ReactComponent as Google } from "../assets/svg/google.svg";
import Input from "../components/input/input";
import Button from "../components/button/button";
import { useForm, Controller } from "react-hook-form";
import { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { handleError } from "./login";

const Register = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
  });

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const res = await axios.post("api/v1/user", data);
      toast.success(res.data.message);
      setLoading(false);
      navigate("/verify-email", { state: { email: data.email } });
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.message);
      handleError(err, toast)
      setLoading(false);
    }
  };
  return (
    <div>
      <ReuseBox>
        <div className="">
          <div className="text-center max-w-xl mx-auto my-4">
            <h1 className="font-semibold text-2xl">Sign up to Alloy</h1>
            <p className="text-sm">Measure your monthly sales performance</p>
          </div>
          <form className="max-w-md mx-auto" onSubmit={handleSubmit(onSubmit)}>
            <div className="grid sm:grid-cols-2 gap-4">
              <div>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { value, onChange } }) => (
                    <Input
                      placeholder="First name"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                {errors.firstName && (
                  <p className="text-red-500 text-xs">
                    This field is required.
                  </p>
                )}
              </div>
              <div>
                <Controller
                  name="lastName"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { value, onChange } }) => (
                    <Input
                      placeholder="Last name"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                {errors.lastName && (
                  <p className="text-red-500 text-xs">
                    This field is required.
                  </p>
                )}
              </div>
              <div className="col-span-2">
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: true,
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Invalid email format",
                    },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <Input
                      placeholder="Email"
                      type={"email"}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                {errors.email && (
                  <p className="text-red-500 text-xs">
                    This field is required.
                  </p>
                )}
              </div>
              <div className="col-span-2">
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: "This field is required",
                    pattern: {
                      value:
                        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).{8,}$/g,
                      message:
                        "Your password must have at least one uppercase letter, one lowercase letter, one digit, one special character, and a minimum length of 8 characters.",
                    },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <Input
                      placeholder="Password"
                      type={"password"}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                {errors.password && (
                  <p className="text-red-500 text-xs">
                    {errors.password.message}
                  </p>
                )}
              </div>
            </div>
            <div className="mt-4">
              <p className="text-gray-400 text-xs">
                By creating an account you agree to our{" "}
                <span className="font-semibold text-black">privacy policy</span>{" "}
                and{" "}
                <span className="font-semibold text-black">
                  electronic communication policy
                </span>
              </p>
            </div>
            <div className="mt-8">
              <Button
                text={"Sign up"}
                style={"w-full"}
                type={"submit"}
                loading={loading}
              />
            </div>
            <div className="flex items-center gap-2 my-4">
              <div className="border border-gray-200 w-full"></div>
              <p className="font-bold whitespace-nowrap text-xs">
                Or with email
              </p>
              <div className="border border-gray-200 w-full"></div>
            </div>
            <div>
              <button className="h-12 w-full border rounded-md border-gray-400 flex items-center justify-center gap-2">
                <span>
                  <Google />
                </span>
                <span className="font-semibold text-sm">
                  Sign in with Google
                </span>
              </button>
            </div>
            <div className="text-center mt-4">
              <p className="text-xs font-semibold">
                Don’t have an account?{" "}
                <span
                  className="font-bold hover:text-yellow-600 cursor-pointer"
                  onClick={() => navigate("/login")}
                >
                  Sign in
                </span>
              </p>
            </div>
          </form>
        </div>
      </ReuseBox>
    </div>
  );
};

export default Register;

export const ReuseBox = ({ children }) => {
  return (
    <div className="grid md:grid-cols-2 h-screen relative z-10 overflow-scroll">
      <div className="relative md:w-full bg-white rounded-t-2xl  md:rounded-tr-none md:rounded-l-2xl col-spa-3 p-8 md:p-16 order-2 md:order-1">
        <div className="absolute top-0 py-4 flex gap-2 items-center">
          <Brand />
          <p className="text-xl font-bold">Alloy</p>
        </div>
        {children}
      </div>
      <div
        className="relative md:h-screen overflow-hidden order-1 md:order-2 hidden md:block"
        // style={{ background: `url("../assets/signup-bg.png") no-repeat` }}
      >
        <div className="absolute inset-0">
          <img src={require("../assets/sign-up-bg.png")} alt="" />
        </div>
        <div className="relative z-20 flex flex-col justify-center items-center gap-y-8 md:h-full">
          <div className="hidden md:block">
            <img
              src={require("../assets/auth-hero.png")}
              alt=""
              className="h max-h-[400px]"
            />
          </div>
          <div className="text-center max-w-xl mx-auto p-4">
            <h3 className="text-2xl font-medium">Get Started with Alloy</h3>
            <p>
              Explore unique customization tool to manage your sales team's
              needs and analysis your sales and performance report.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
