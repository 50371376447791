import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Brand from "../components/brand/brand";
import { ReactComponent as Google } from "../assets/svg/google.svg";
import Input from "../components/input/input";
import Button from "../components/button/button";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "axios";
import { ReuseBox } from "./register";
import { handleError } from "./login";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
      otp: "",
    },
  });

  const validatePassword = (value) => {
    // Use watch to get the value of the password field
    const password = watch("password");
    // Check if the passwords match
    return value === password || "Passwords do not match";
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const res = await axios.post("api/v1/user/reset-password", data);
      setLoading(false);
      toast.success(res.data.message);
      navigate("/login");
    } catch (err) {
      console.log(err);
      toast.error(err.response.data?.message);
      handleError(err, toast)
      setLoading(false);
    }
  };
  return (
    <div>
      <ReuseBox>
        <div className="flex h-full items-center flex-col justify-center">
          <div className="text-center max-w-xl mx-auto my-4">
            <h1 className="font-semibold text-2xl">Reset Password</h1>
            <p className="text-sm">
              Enter your new password
            </p>
          </div>
          <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
            <div className="grid sm:grid-cols-2 gap-4">
              <div className="col-span-2">
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: "This field is required",
                    pattern: {
                      value:
                        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).{8,}$/g,
                      message:
                        "Your password must have at least one uppercase letter, one lowercase letter, one digit, one special character, and a minimum length of 8 characters.",
                    },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <Input
                      placeholder="Password"
                      type={"password"}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                {errors.password && (
                  <p className="text-red-500 text-xs">
                    {errors.password.message}
                  </p>
                )}
              </div>
              <div className="col-span-2">
                <Controller
                  name="confirmPassword"
                  control={control}
                  rules={{
                    required: "This field is required",
                    validate: validatePassword,
                  }}
                  render={({ field: { value, onChange } }) => (
                    <Input
                      placeholder="Confirm Password"
                      type={"password"}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                {errors.confirmPassword && (
                  <p className="text-red-500 text-xs">
                    {errors.confirmPassword.message}
                  </p>
                )}
              </div>
              <div className="col-span-2">
                <Controller
                  name="otp"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { value, onChange } }) => (
                    <Input
                      placeholder="Otp"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                {errors.otp && (
                  <p className="text-red-500 text-xs">
                    This field is required.
                  </p>
                )}
              </div>
            </div>
            <div className="mt-8">
              <Button text={"Reset"} style={"w-full"} loading={loading}></Button>
            </div>
          </form>
        </div>
      </ReuseBox>
    </div>
  );
};

export default ForgotPassword;
