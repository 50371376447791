import { createSelector } from "@reduxjs/toolkit";

const dashboardSlice = (state) => state.dashboard;

export const selectSalesStats = createSelector(
  [dashboardSlice],
  (sales) => sales.sales
);

export const selectRevenueStats = createSelector(
  [dashboardSlice],
  (revenue) => revenue.revenue
);

export const selectCardStats = createSelector(
  [dashboardSlice],
  (card) => card.card
);

export const selectInicatorStats = createSelector(
  [dashboardSlice],
  (indicator) => indicator.indicator
);
