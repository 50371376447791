import React, { useEffect } from "react";
import { CSVDownload, CSVLink } from "react-csv";
import { useLocation, useNavigate } from "react-router-dom";
import { BsFileEarmarkText } from "react-icons/bs";
import Button from "../../components/button/button";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";

var _round = Math.round;
Math.round = function (number, decimals /* optional, default 0 */) {
  if (arguments.length == 1) return _round(number);

  var multiplier = Math.pow(10, decimals);
  return _round(number * multiplier) / multiplier;
};

const Report = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const report = location.state;

  useEffect(() => {
    if (!report) return navigate("/");
  }, []);

  const subData = report?.report.subcomponents.map((item) => [
    "",
    "",
    "",
    item.title,
    report?.radio?.unit.toLowerCase() === "lbs"
      ? 1000
      : report?.radio?.unit.toLowerCase() === "kg" && 1,
    item?.price,
    item?.alloySubrange.astm,
    item?.alloyRange.title,
    item?.alloySubrange.out,
    Math.round(item?.weight, 2),
    Math.round(item?.alloyValue * report.allRates[item._id], 2),
    "-",
    "-",
  ]);

  const reportData = [
    [
      "Supplier Part No",
      "Customer Part No",
      "Delivery Plant",
      "Part",
      "Unit",
      "Price",
      "Grade",
      "Out",
      "Out",
      "Type",
      "Weight",
      "Alloy surcharge (per part)",
      "Extra charges",
      ` Total (Incl Alloys) \n(${
        report.radio.currency + "/" + report.radio.unit
      })`,
    ],
    [
      report.report.SupplierPartNumber,
      report.report.CustomerPartNumber,
      report.report.deliveryPlant,
      report?.report.title,
      report?.radio?.unit.toLowerCase() === "lbs"
        ? 1000
        : report?.radio?.unit.toLowerCase() === "kg" && 1,
      Math.round(report?.report?.price, 2),
      "-",
      "-",
      "-",
      report?.report?.weight,
      Math.round(report?.report?.alloyValue * report.avgRate, 2),
      "-",
      "-",
      Math.round(
        report?.report?.alloyValue * report.avgRate + report?.report?.price,
        2
      ),
    ],
    ...subData,
  ];

  const handlePdfExport = () => {
    const doc = new jsPDF();

    doc.autoTable({
      head: [reportData[0]],
      body: reportData.slice(1),
    });

    doc.save(`${report.report.title}_report.pdf`);
  };

  useEffect(() => {}, []);
  return (
    <div className="p-4 overflow-auto min-h-screen no-scrollbar">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold">Report</h1>
        <div className="flex items-center gap-4">
          <Button
            text={"Export as pdf"}
            icon={<BsFileEarmarkText />}
            handleButton={handlePdfExport}
          />
          <CSVLink data={reportData} target="_blank">
            <Button text={"Export as csv"} icon={<BsFileEarmarkText />} />
          </CSVLink>
        </div>
      </div>
      <div className="bg-white rounded-xl mt-4 h-full">
        <div className="border-b border-gray-200 p-4">
          <h2 className="font-semibold text-lg mb-4">
            Report for {report?.report.title}
          </h2>
          <div className="space-y-1">
            <p>
              <span className="font-bold">Supplier Part No:</span>
              {report.report.SupplierPartNumber}
            </p>
            <p>
              <span className="font-bold">Customer Part No:</span>
              {report.report.CustomerPartNumber}
            </p>
            <p>
              <span className="font-bold">Delivery Plant:</span>
              {report.report.deliveryPlant}
            </p>
          </div>
        </div>
        <div className="p-4 max-w-4xl">
          <div className="overflow-x-auto no-scrollbar pb-20 rounded-xl border border-gray-200">
            <table className="w-full text-center rounded-xl">
              <colgroup>
                <col span={6} />
              </colgroup>
              <thead>
                <tr className="border-b border-gray-200">
                  <td className="p-2 capitalize min-w-40 font-semibold text-sm">
                    <p className="mb-2">Part</p>
                  </td>
                  <td className="p-2 capitalize min-w-40 font-semibold text-sm">
                    <p className="mb-2">Unit</p>
                  </td>
                  <td className="p-2 capitalize min-w-40 font-semibold text-sm">
                    <p className="mb-2">Price</p>
                  </td>
                  <td className="p-2 capitalize min-w-40 font-semibold text-sm">
                    <p className="mb-2">Grade</p>
                  </td>
                  <td className="p-2 capitalize min-w-40 font-semibold text-sm">
                    <p className="mb-2">Out</p>
                  </td>
                  <td className="p-2 capitalize min-w-40 font-semibold text-sm">
                    <p className="mb-2">Type</p>
                  </td>
                  <td className="p-2 capitalize min-w-40 font-semibold text-sm">
                    <p className="mb-2">Weight</p>
                  </td>
                  <td className="p-2 capitalize min-w-40 font-semibold text-sm">
                    <p className="mb-2">Alloy surcharge per part</p>
                  </td>
                  <td className="p-2 capitalize min-w-40 font-semibold text-sm">
                    <p className="mb-2">Extra charges</p>
                  </td>
                  <td className="p-2 min-w-40 font-semibold text-sm">
                    <p className="mb-2">
                      Total (Incl Alloys) <br />(
                      {report.radio.currency + "/" + report.radio.unit})
                    </p>
                  </td>
                </tr>
              </thead>
              <tbody className="relative">
                <tr className="border-b border-gray-200 font-semibold text-sm whitespace-nowrap">
                  <td className="col p-2">{report?.report.title}</td>
                  <td className="col p-2">
                    {report?.radio?.unit.toLowerCase() === "lbs"
                      ? 1000
                      : report?.radio?.unit.toLowerCase() === "kg" && 1}
                  </td>
                  <td className="col p-2">
                    {Math.round(report?.report?.price, 2)}
                  </td>
                  <td className="col p-2 text-center">-</td>
                  <td className="col p-2 text-center">-</td>
                  <td className="col p-2 text-center">-</td>
                  <td className="col p-2">{report?.report?.weight}</td>
                  <td className="col p-2">
                    {Math.round(
                      report?.report?.alloyValue * report.avgRate,
                      2
                    )}
                  </td>
                  <td className="col p-2 text-center">-</td>
                  <td className="col p-2">
                    {Math.round(
                      report?.report?.alloyValue * report.avgRate +
                        report?.report?.price,
                      2
                    )}
                  </td>
                </tr>
                {report?.report.subcomponents.map((item) => (
                  <tr className="border-b border-gray-200 font-semibold text-sm whitespace-nowrap">
                    <td className="col p-2">{item.title}</td>
                    <td className="col p-2 text-center">
                      {report?.radio?.unit.toLowerCase() === "lbs"
                        ? 1000
                        : report?.radio?.unit.toLowerCase() === "kg" && 1}
                    </td>
                    <td className="col p-2">{item?.price}</td>
                    <td className="col p-2 text-center">
                      {item?.alloySubrange.astm}
                    </td>
                    <td className="col p-2 text-center">
                      {item?.alloyRange.title}
                    </td>
                    <td className="col p-2 text-center">
                      {item?.alloySubrange.out}
                    </td>
                    <td className="col p-2">{Math.round(item?.weight, 2)}</td>
                    <td className="col p-2">
                      {Math.round(item?.alloyValue * report.allRates[item._id], 2)}
                    </td>
                    <td className="col p-2 text-center">-</td>
                    <td className="col p-2 text-center">-</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Report;
