/* eslint-disable react/prop-types */
import { createContext, useEffect } from "react";
import { LiaHomeSolid } from "react-icons/lia";
import { MdOutlinePerson } from "react-icons/md";
import { TfiSettings } from "react-icons/tfi";
import { GoDatabase } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import axios from "axios";
import { ReactComponent as DashboardIcon } from "../assets/svg/dashboard.svg";
import { ReactComponent as ProductIcon } from "../assets/svg/product.svg";
import { ReactComponent as CalculationIcon } from "../assets/svg/calculation.svg";
import { ReactComponent as ReportIcon } from "../assets/svg/report.svg";
import { ReactComponent as CompanyIcon } from "../assets/svg/company.svg";
import { selectAllCompanies } from "./selectors/companySelector";

export const GlobalState = createContext();

const DataProvider = ({ children }) => {
  const companies = useSelector(selectAllCompanies);
  const dispatch = useDispatch();
  const [nav, setNav] = useState(false);
  const [company, setCompany] = useState("");
  let handleCapitalize = (word) => {
    let splitter = word.trim().split(" ");
    let firstCap = splitter[0].split("");
    let remaining = splitter.slice(1, splitter.length).join(" ");

    let firstCapOne = firstCap[0].toUpperCase();
    let firstCapTwo = firstCap.slice(1, firstCap.length).join("");

    let joinFirst = `${firstCapOne}${firstCapTwo}`;

    return `${joinFirst} ${remaining}`;
  };

  let numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  var _round = Math.round;
  Math.round = function (number, decimals /* optional, default 0 */) {
    if (arguments.length == 1) return _round(number);

    var multiplier = Math.pow(10, decimals);
    return _round(number * multiplier) / multiplier;
  };

  const toggleNav = () => {
    setNav(!nav);
  };

  let { auth } = useSelector((state) => state);

  const getAllProductCategories = async () => {
    const res = await axios.get("api/v1/product?pagination=no");
    const products = res.data.data.docs;
    const categories = products.map((product) => {
      return product.category;
    });
    const unique = [...new Set(categories)];
    return unique;
  };

  let sidebarList = [
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: <DashboardIcon size={24} />,
      permission: ["user"],
    },
    {
      name: "Products",
      url: "/products",
      icon: <ProductIcon className="icon" size={24} />,
      permission: ["user"],
    },
    // {
    //   name: "Calculation",
    //   url: "/calculation",
    //   icon: <CalculationIcon className="icon" size={24} />,
    //   permission: ["user"],
    // },
    {
      name: "Report",
      url: "/report",
      icon: <ReportIcon className="icon" size={24} />,
      permission: ["admin", "user"],
    },
    {
      name: "Manage Company",
      url: "/manage-company",
      icon: <CompanyIcon className="icon" size={24} />,
      permission: ["admin", "user"],
    },
  ];

  const state = {
    handleCapitalize,

    numberWithCommas,

    sidebarList,
    auth,
    nav,
    toggleNav,
    company,
    setCompany,
    getAllProductCategories,
  };

  return <GlobalState.Provider value={state}>{children}</GlobalState.Provider>;
};

export default DataProvider;
