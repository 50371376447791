import React, { useContext } from "react";
import { GlobalState } from "../../data/Context";

export const SmallNav = ({ menu, handleNav, active, full, theme }) => {
  // let [active, setActive] = useState(menu?.[0]);

  return (
    <div className="">
      <nav className="flex items-center gap-8 px-1 overflow-x-auto  scrollbar-hide">
        {menu?.map((item, i) => (
          <div
            key={i}
            onClick={handleNav ? () => handleNav(item) : null}
            className={`py-2 font-body capitalize ${
              full ? "w-full" : "w-fit"
            } whitespace-nowrap text-sm p-2 cursor-pointer border-b-2 ${
              active === item
                ? "border-[#2E700E] font-bold text-[#2E700E] bg-[#F3F4F6] rounded-t-md"
                : "border-transparent text-gray-600"
            }`}
          >
            {item?.replace("-", " ")}
          </div>
        ))}
      </nav>
    </div>
  );
};

export default SmallNav;
