import React from "react";
import { useNavigate } from "react-router-dom";
import Profilerounded from "../profile-rounded/profile-rounded";

const NotificationItem = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div class="flex px-4 py-3 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
        onClick={() => navigate("/notifications/id")}>
        <div
          class="flex-shrink-0"
        >
          <Profilerounded sm />{" "}
          <div class="absolute flex items-center justify-center w-5 h-5 ml-6 -mt-5 bg-blue-600 border border-white rounded-full dark:border-gray-800">
            <svg
              class="w-3 h-3 text-white"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"></path>
              <path d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"></path>
            </svg>
          </div>
        </div>
        <div class="w-full pl-3">
          <div class="text-gray-500 text-sm mb-1.5 dark:text-gray-400">
            New message from{" "}
            <span class="font-semibold text-gray-900 dark:text-white">
              Jese Leos
            </span>
            : "Hey, what's up? All set for the presentation?"
          </div>
          <div class="text-xs text-blue-600 dark:text-blue-500">
            a few moments ago
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationItem;
