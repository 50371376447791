import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { BiSearch } from "react-icons/bi";
// import { VscListSelection } from "react-icons/vsc";

import Profilerounded from "../profile-rounded/profile-rounded";
import { useSelector } from "react-redux";
// import Profile from "../profile/profile";
import Notification from "../notification/notification/notification";
import { selectUser } from "../../data/selectors/userSelector";

const DefaultHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  return (
    <div className="mb-2 p-4 bg-white">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div className="search flex items-center w-fit px-4 rounded-full md:border md:bg-gray-100">
            <span className="text-gray-300">
              <BiSearch />
            </span>
            <input
              type="text"
              placeholder="Share here..."
              className="md:w-80 outline-none hidden md:block bg-transparent border-none font-light text-sm h-10 px-2"
            />
          </div>
        </div>
        <div className="profile flex items-center gap-4">
          {/* <span>
            <Notification />
          </span> */}
          <div className="flex items-center gap-2" onClick={() => navigate('/settings')}>
            <Profilerounded sm img={user?.image?.url} />
            <p className="font-medium text-sm hidden md:block">{`${user?.firstName} ${user.lastName}`}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultHeader;
