import React from "react";
import Brand from "../brand/brand";
import FootIcon from "../foot-icon/foot-icon";

import { BsInstagram } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa6";

const Footer = () => {
  return (
    <div className="bg-white px-4 pt-8 font-koho">
      <div className="max-w-5xl mx-auto">
        <div className="grid lg:grid-cols-2 gap-4 md:gap-12">
          <div>
            <div className="flex items-center gap-4">
              <Brand />
              <p className="font-bold text-2xl">Alloy</p>
            </div>
            <p className="text-[#1E1E1E] text-3xl md:text-4xl mt-4 font-black">
              Manage your sales
              <br />
              and inventories
            </p>
            {/* <div className="flex gap-4 mt-4 flex-wrap">
              <FootIcon />
              <FootIcon icon={<BsInstagram />} />
              <FootIcon icon={<FaLinkedinIn />} />
              <FootIcon icon={<FaTwitter />} />
              <FootIcon icon={<FaYoutube />} />
            </div> */}
          </div>
          <div className="">
            <div className="grid md:grid-cols-2">
              <div>
                {/* <h4 className="font-bold text-lg">Support</h4> */}
                <ul className="mt-4 space-y-2">
                  <li>Product</li>
                  <li>Features</li>
                  <li>Pricing</li>
                  <li>Support</li>
                </ul>
              </div>
              <div>
                {/* <h4 className="font-bold text-lg">Support</h4> */}
                <ul className="mt-4 space-y-4">
                  <li className="flex gap-2">
                    <FootIcon icon={<BsInstagram />} />
                    <span className="font-semibold">
                      775 Rolling Green Rd. <br />
                      Pecan St. London
                    </span>
                  </li>
                  <li className="flex gap-2">
                    <FootIcon icon={<BsInstagram />} />
                    <span className="font-semibod">Contact @alloy.com</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="max-w-lg ml-auto mt-16">
          <form>
            <label
              for="default-search"
              class="mb-2 text-sm font-medium text-gray-900 sr-only"
            >
              Search
            </label>
            <div class="relative">
              <input
                type="search"
                id="default-search"
                class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                placeholder="Subscribe to Our Newsletter"
                required
              />
              <button
                type="submit"
                class="text-white absolute end-2.5 bottom-2.5 bg-[#BBE809] outline-none font-medium rounded-lg text-sm px-4 py-2"
              >
                Search
              </button>
            </div>
          </form>
        </div> */}
      </div>
      <div className="mt-8 text-center border-t-2 p-4">
        <p className="h font-semibold">
        © 2024 Steel Sales Management Platform. All rights reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
