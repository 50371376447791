import { createSelector } from "@reduxjs/toolkit";

const product = (state) => state.product;

export const selectProducts = createSelector(
  [product],
  (product) => product.products
);

export const selectProductLoading = createSelector(
  [product],
  (loader) => loader.loading
);

export const selectPlans = createSelector([product], (plan) => plan.plans);

export const selectSubscriptions = createSelector(
  [product],
  (subscription) => subscription.subscriptions
);

export const selectSubProducts = createSelector(
  [product],
  (products) => products.subProducts
);

export const selectSubProductsLoading = createSelector(
  [product],
  (loading) => loading.subProductsLoading
);
