import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  products: [],
  productLoading: false,
  productError: null,
  types: [],
  typeLoading: false,
  typeError: null,
  ranges: [],
  rangeLoading: false,
  rangeError: null,
  subRanges: [],
  subRangeLoading: false,
  subRangeError: null,
  subRangesById: [],
  subRangeByIdLoading: false,
  subRangeByIdError: null,
  periods: [],
  periodLoading: false,
  periodError: null,
};

const SurchargeSlice = createSlice({
  name: "surcharge",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSurchargeProducts.pending, (state) => {
      state.productLoading = true;
      state.productError = null;
    });
    builder.addCase(fetchSurchargeProducts.fulfilled, (state, { payload }) => {
      state.productLoading = false;
      state.products = payload.data;
    });
    builder.addCase(fetchSurchargeProducts.rejected, (state, { error }) => {
      state.productLoading = false;
      state.productError = error;
    });
    builder.addCase(fetchSurchargeTypes.pending, (state) => {
      state.typeLoading = true;
      state.typeError = null;
    });
    builder.addCase(fetchSurchargeTypes.fulfilled, (state, { payload }) => {
      state.typeLoading = false;
      state.types = payload.data;
    });
    builder.addCase(fetchSurchargeTypes.rejected, (state, { error }) => {
      state.typeLoading = false;
      state.typeError = error;
    });
    builder.addCase(fetchSurchargceRanges.pending, (state) => {
      state.rangeLoading = true;
      state.rangeError = null;
    });
    builder.addCase(fetchSurchargceRanges.fulfilled, (state, { payload }) => {
      state.rangeLoading = false;
      state.ranges = payload.data;
    });
    builder.addCase(fetchSurchargceRanges.rejected, (state, { error }) => {
      state.rangeLoading = false;
      state.rangeError = error;
    });
    builder.addCase(fetchSurchargceSubRanges.pending, (state) => {
      state.subRangeLoading = true;
      state.subRangeError = null;
    });
    builder.addCase(
      fetchSurchargceSubRanges.fulfilled,
      (state, { payload }) => {
        state.subRangeLoading = false;
        state.subRanges = payload.data;
      }
    );
    builder.addCase(fetchSurchargceSubRanges.rejected, (state, { error }) => {
      state.subRangeLoading = false;
      state.subRangeError = error;
    });
    builder.addCase(fetchSurchargeSubRangesById.pending, (state) => {
      state.subRangeByIdLoading = true;
      state.subRangeByIdError = null;
    });
    builder.addCase(
      fetchSurchargeSubRangesById.fulfilled,
      (state, { payload }) => {
        state.subRangeByIdLoading = false;
        state.subRangesById = payload.data;
      }
    );
    builder.addCase(
      fetchSurchargeSubRangesById.rejected,
      (state, { error }) => {
        state.subRangeByIdLoading = false;
        state.subRangeByIdError = error;
      }
    );
    builder.addCase(fetchSurchargePeriods.pending, (state) => {
      state.periodLoading = true;
      state.periodError = null;
    });
    builder.addCase(fetchSurchargePeriods.fulfilled, (state, { payload }) => {
      state.periodLoading = false;
      state.periods = payload.data;
    });
    builder.addCase(fetchSurchargePeriods.rejected, (state, { error }) => {
      state.periodLoading = false;
      state.periodError = error;
    });
  },
});

export const {} = SurchargeSlice.actions;
export default SurchargeSlice.reducer;

export const fetchSurchargeProducts = createAsyncThunk(
  "surcharge/fetchSurchargeProducts",
  async (_, thunkAPI) => {
    try {
      const res = await axios.get("api/v1/surcharge/product");
      return res.data;
    } catch (err) {
      console.log({ fetchSurchargeProducts: err });
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const fetchSurchargeTypes = createAsyncThunk(
  "surcharge/fetchSurchargeTypes",
  async (_, thunkAPI) => {
    try {
      const res = await axios.get("api/v1/surcharge/type");
      return res.data;
    } catch (err) {
      console.log({ fetchSurchargeTypes: err });
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const fetchSurchargceRanges = createAsyncThunk(
  "surcharge/fetchSurchargceRanges",
  async (type, thunkAPI) => {
    try {
      const res = await axios.get(`api/v1/surcharge/range`);
      return res.data;
    } catch (err) {
      console.log({ fetchSurchargceRanges: err });
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const fetchSurchargceSubRanges = createAsyncThunk(
  "surcharge/fetchSurchargceSubRanges",
  async (type, thunkAPI) => {
    try {
      const res = await axios.get(`api/v1/surcharge/subRange`);
      return res.data;
    } catch (err) {
      console.log({ fetchSurchargceSubRanges: err });
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);
export const fetchSurchargeSubRangesById = createAsyncThunk(
  "surcharge/fetchSurchargeSubRangesById",
  async (range, thunkAPI) => {
    try {
      const res = await axios.get(`api/v1/surcharge/subrange?range=${range}`);
      return res.data;
    } catch (err) {
      console.log({ fetchSurchargeSubRangesById: err });
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);
export const fetchSurchargePeriods = createAsyncThunk(
  "surcharge/fetchSurchargePeriods",
  async (_, thunkAPI) => {
    try {
      const res = await axios.get("api/v1/surcharge/time");
      return res.data;
    } catch (err) {
      console.log({ fetchSurchargePeriods: err });
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const getPeriodsForSubproduct = (subProduct, periods) => {
  const subPeriods = {
    subProduct: subProduct,
    periods: [],
  };
  periods?.docs
    ?.filter((period) => period.subrange._id === subProduct.alloySubrange._id)
    ?.forEach((period) => {
      subPeriods.periods.push({ month: period.timeFrame, value: period.value });
    });
  return {
    ...subPeriods,
    periods: sortByYearAndMonth(subPeriods.periods),
    average: "",
  };
};

export const getPeriodsForSubproducts = (subProducts, periods) => {
  const subPeriods = subProducts?.docs?.map((product) =>
    getPeriodsForSubproduct(product, periods)
  );
  return subPeriods;
};

const sortByYearAndMonth = (data) => {
  return data.sort((a, b) => {
    const dateA = new Date(a.month);
    const dateB = new Date(b.month);

    // First compare the years
    const yearDiff = dateA.getFullYear() - dateB.getFullYear();
    if (yearDiff !== 0) {
      return yearDiff;
    }

    // If years are the same, compare the months
    return dateA.getMonth() - dateB.getMonth();
  });
};

export const getAverageOfMonths = (subProduct, start, end) => {
  subProduct = {
    subProduct: {},
    periods: [],
    average: "",
  };

  const indexOfStart = subProduct.periods.findIndex(
    (period) => period.month === start
  );
  const indexOfEnd = subProduct.periods.findIndex(
    (period) => period.month === end
  );

  const periods = subProduct.periods.slice(indexOfStart, indexOfEnd + 1);
  let average = 0;
  let total = 0;
  periods.forEach((period) => {
    total += Number(period.value);
  });
  average = total / periods.length;
  return { ...subProduct, average };
};
