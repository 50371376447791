import React, { useEffect, useState } from "react";
import Input, { ImageUploader } from "../../components/input/input";
import Button from "../../components/button/button";
import FAQ from "../../components/faq/faq";
import ModalContainer from "../../components/modal-container/modal-container";
import { useDropzone } from "react-dropzone";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  selectContentLoading,
  selectFaq,
} from "../../data/selectors/contentSelector";
import { fetchFaq } from "../../data/Reducers/contentSlice";
import Loader from "../../components/loader/loader";
import { handleError } from "../../screens/login";
import { toast } from "react-toastify";
import AccordionGroup from "@mui/joy/AccordionGroup";
import Accordion from "@mui/joy/Accordion";
import AccordionDetails from "@mui/joy/AccordionDetails";
import AccordionSummary from "@mui/joy/AccordionSummary";

const SupportTicket = () => {
  const dispatch = useDispatch();
  const faqs = useSelector(selectFaq);
  const [index, setIndex] = React.useState(0);
  const faqLoading = useSelector(selectContentLoading);
  const [isOpen, setIsOpen] = useState(false);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      title: "",
      category: "",
      description: "",
      image: "",
    },
  });

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmitTicket = async (data) => {
    setLoading(true);
    try {
      let res = await axios.post("api/v1/ticket", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success(res.data.message);
      toggleModal();
      reset();
    } catch (err) {
      console.log(err);
      handleError(err, toast);
    }
    setLoading(false);
  };

  useEffect(() => {
    dispatch(fetchFaq());
  }, []);
  return (
    <div className="p-4 overflow-auto no-scrollbar">
      <div className="">
        <h1 className="text-2xl font-bold">Support Ticket</h1>
      </div>
      <div className="grid md:grid-cols-3 gap-4">
        <div className="md:col-span-2">
          <div className="bg-white rounded-xl mt-4 pb-20 h-full">
            <div className="border-b border-gray-200 p-4">
              <h2 className="font-semibold text-md">Support Ticket </h2>
            </div>
            <div className="p-4">
              <h6 className="font-semibold text-sm">Create New Ticket</h6>
              <p className="text-sm">
                Fill up all the information here, then click submit button
              </p>
              <form onSubmit={handleSubmit(handleSubmitTicket)}>
                <div className="grid md:grid-cols-2 gap-4 mt-6">
                  <div>
                    <Controller
                      name="title"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { value, onChange } }) => (
                        <Input
                          label={"Ticket Title"}
                          placeholder={"Default"}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                    {errors.title && (
                      <p className="text-red-500 text-xs">
                        This field is required.
                      </p>
                    )}
                  </div>
                  <div>
                    <Controller
                      name="category"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { value, onChange } }) => (
                        <Input
                          label={"Ticket category "}
                          type={"select"}
                          selectHolder={"Select type"}
                          options={["account", "subscription"]}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                    {errors.category && (
                      <p className="text-red-500 text-xs">
                        This field is required.
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <Controller
                      name="description"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { value, onChange } }) => (
                        <Input
                          label={"Content Description"}
                          type={"textArea"}
                          placeholder={"Type course description"}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                    {errors.description && (
                      <p className="text-red-500 text-xs">
                        This field is required.
                      </p>
                    )}
                  </div>
                  <div className="col-span-2">
                    <ImageUploader
                      acceptedFiles={acceptedFiles}
                      inputProps={getInputProps}
                      rootProps={getRootProps}
                    />
                  </div>
                </div>
                <div className="flex items-center justify-end gap-8 mt-6">
                  <button
                    type="reset"
                    className="h-10 px-4 font-medium border border-black rounded-md"
                  >
                    Cancel
                  </button>
                  <Button text={"Submit Ticket"} loading={loading} />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="">
          <div className="bg-white rounded-xl mt-4 pb-20 h-full">
            <div className="border-b border-gray-200 p-4">
              <h2 className="font-semibold text-md">
                Frequently Asked Questions
              </h2>
            </div>
            <div className="p-4">
              <div className="bg-[#F3F4F6] p-4 rounded-lg">
                <h6 className="text-sm font-semibold">
                  How do ticket get solved
                </h6>
                <p className="text-xs">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                  sed iaculis dui. Morbi luctus iaculis est, eu egestas eros
                  lacinia sed. Mauris sagittis tempor ligula, at auctor odio
                  ....
                </p>
              </div>
              <div className="mt-8">
                {faqLoading ? (
                  <Loader />
                ) : (
                  faqs.docs?.length && (
                    <AccordionGroup sx={{border: "1px solid grey", borderRadius: 8}}>
                      {faqs.docs?.map((faq, i) => (
                        <Accordion
                          expanded={index === i}
                          onChange={(event, expanded) => {
                            setIndex(expanded ? i : null);
                          }}
                        >
                          <AccordionSummary>{faq.title}</AccordionSummary>
                          <AccordionDetails>{faq.description}</AccordionDetails>
                        </Accordion>
                      ))}
                    </AccordionGroup>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalContainer show={isOpen} close={toggleModal}>
        <div className="text-center p-10 space-y-4">
          <h3 className="font-semibold text-lg">Ticket Confirmation</h3>
          <p className="text-sm max-w-sm mx-auto">
            You ticket have been received. The issue will be address and
            communication with you through your email account.{" "}
          </p>
          <div className="flex justify-center">
            <Button text={"Continue"} handleButton={toggleModal} />
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default SupportTicket;
