import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../components/button/button";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "axios";
import { ReuseBox } from "./register";
import { OtpComponent } from "../components/ModalComponents";
import { handleError } from "./login";

export const resendToken = async (email) => {
  const res = await axios.patch("/api/v1/user/resend-otp", { email });
  return res;
};

const VerifyEmail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [code, setCode] = useState("");
  const email = location.state?.email;
  const [isLoading, setIsLaodig] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      code: "",
    },
  });

  const resendToken = async () => {
    setIsLaodig(true);
    try {
      const res = await resendToken(email);
      toast.success(res.data.message);
      setIsLaodig(false);
    } catch (err) {
      console.log(err);
      setIsLaodig(false);
      handleError(err, toast);
    }
  };

  const onSubmit = async () => {
    if (!code) return toast.error("Otp is required");
    setLoading(true);
    try {
      const res = await axios.post("api/v1/user/otp", { otp: code });
      setLoading(false);
      navigate("/login");
    } catch (err) {
      console.log(err);
      setLoading(false);
      handleError(err, toast);
    }
  };

  return (
    <div>
      <ReuseBox>
        <div className="flex h-full items-center flex-col justify-center">
          <div className="text-center max-w-xl mx-auto my-4">
            <h1 className="font-semibold text-2xl">
              Verify your email address
            </h1>
            <p className="text-sm text-gray-500 max-w-xs mt-4">
              A verification code has been sent to useraccount@gmail.com
            </p>
          </div>
          <form className="max-w-md mx-auto" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-2 items-center justify-center max-w-xs">
              <p className="text-xs font-medium text-gray-500">
                Please check your indox and enter the verification code below to
                verify your email address. the code will expire in 50:00.
              </p>
              <OtpComponent
                stateData={code}
                textChange={(data) => {
                  setCode(data);
                }}
                css="borderColor"
                loading={isLoading}
                numInputs={6}
              />
            </div>
            <div className="mt-8 w-full">
              <Button
                text={"Verify"}
                style={"w-full"}
                loading={loading}
              ></Button>
            </div>
            <div className="text-center mt-4">
              <p className="text-sm font-semibold">
                <button
                  className="font-bold text-green-600 hover:text-yellow-600 cursor-pointer"
                  onClick={resendToken}
                  disabled={isLoading}
                >
                  Resend Code
                </button>
              </p>
            </div>
          </form>
        </div>
      </ReuseBox>
    </div>
  );
};

export default VerifyEmail;
