import React from "react";
import { SlOptionsVertical } from "react-icons/sl";
import { useNavigate } from "react-router-dom";

const NewsCard = ({ item }) => {
  const navigate = useNavigate()
  return (
    <div className="max-w-xs mx-auto bg-white border border-gray-200 rounded-lg shadow">
      <img
        className="rounded-t-lg w-full"
        src={item?.image?.url || require("../../assets/card-img.png")}
        alt=""
      />
      <div className="p-5">
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
          {item?.title || "Enter Surcharges Data"}
        </h5>
        <p className="mb-3 font-normal text-gray-700 text-sm">
          {item?.content
            ? item?.content.length > 100
              ? item?.content.slice(0, 100)
              : item?.content
            : "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellat, eos voluptatum impedit tenetur reprehenderit porro. Doloribus earum vitae et iure?"}
          <br />
          <span
            className="text-blue-400 underline cursor-pointer"
            onClick={() =>
              item && navigate(`/industrial-news/${item?.title}`, { state: item })
            }
          >
            Read more ....{" "}
          </span>
        </p>
        <div className="flex items-center justify-between">
          <p className="font-semibold text-sm">January 21, 2023</p>
          <span className="font-semibold text-sm">
            <SlOptionsVertical />
          </span>
        </div>
      </div>
    </div>
  );
};

export default NewsCard;
