import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  products: [],
  plans: [],
  subscriptions: [],
  subProducts: {},
  subProductsLoading: false,
  loading: false,
  error: null,
};

const ProductSlice = createSlice({
  name: "product",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllProducts.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchAllProducts.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.products = payload.data;
    });
    builder.addCase(fetchAllProducts.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error;
    });
    builder.addCase(fetchAllSubscriptions.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchAllSubscriptions.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.subscriptions = payload.data;
    });
    builder.addCase(fetchAllSubscriptions.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error;
    });
    builder.addCase(fetchAllPlans.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchAllPlans.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.plans = payload.data;
    });
    builder.addCase(fetchAllPlans.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error;
    });
    builder.addCase(fetchAllSubProducts.pending, (state) => {
      state.subProductsLoading = true;
      state.subProducts = {}
      state.error = null;
    });
    builder.addCase(fetchAllSubProducts.fulfilled, (state, { payload }) => {
      state.subProductsLoading = false;
      state.subProducts = payload.data;
    });
    builder.addCase(fetchAllSubProducts.rejected, (state, { error }) => {
      state.subProductsLoading = false;
      state.error = error;
    });
  },
});

export const {} = ProductSlice.actions;
export default ProductSlice.reducer;

export const fetchAllProducts = createAsyncThunk(
  "products/fetchAllProducts",
  async (company, thunkAPI) => {
    try {
      const res = await axios.get("api/v1/product", {
        headers: {
          "company-id": company,
        },
      });
      return res.data;
    } catch (err) {
      console.log({ fetchAllProducts: err });
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const fetchAllPlans = createAsyncThunk(
  "products/fetchAllPlans",
  async () => {
    const res = await axios.get("api/v1/plan");
    return res.data;
  }
);

export const fetchAllSubscriptions = createAsyncThunk(
  "products/fetchAllPfetchAllSubscriptionslans",
  async () => {
    const res = await axios.get("api/v1/subscription/payment");
    return res.data;
  }
);

export const fetchAllSubProducts = createAsyncThunk(
  "products/fetchAllSubProducts",
  async ({ companyId, productId }, thunkAPI) => {
    try {
      const res = await axios.get(
        `api/v1/product/sub-component?product=${productId}`,
        {
          headers: {
            "company-id": companyId,
          },
        }
      );
      return res.data;
    } catch (err) {
      console.log({ fetchAllSubProducts: err });
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);
