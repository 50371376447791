import React from "react";
import Button from "../../components/button/button";
import { MdAdd } from "react-icons/md";
import { BsFileEarmarkText } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Input from "../../components/input/input";

const Calculation = () => {
  const navigate = useNavigate();
  return (
    <div className="p-4 overflow-auto no-scrollbar">
      <div className="flex items-center justify-between gap-6 flex-wrap">
        <h1 className="text-2xl font-bold">Calculator</h1>
        <div className="flex items-center gap-4">
          <Button text={"Save"} icon={<MdAdd />} />
          <Button text={"Export"} icon={<BsFileEarmarkText />} />
        </div>
      </div>
      <div className="bg-white rounded-xl mt-4">
        <div className="flex items-center justify-between border-b border-gray-200 p-4">
          <h2 className="font-semibold text-lg">Surcharges</h2>
          <div className="flex items-center gap-4">
            <h3 className="font-semibold text-sm hidden md:block">Preferred currency</h3>
            <select
              name=""
              className="border border-gray-200 rounded-lg p-2 outline-none"
            >
              <option value="">$</option>
            </select>
          </div>
        </div>
        <div className="p-4 max-w-2xl">
          <div className="border border-gray-200 rounded-lg">
            <div className="p-2 border-b border-gray-200 flex items-center justify-between gap-x-4">
              <p className="font-semibold text-lg">Order Create</p>
              <Button text={"Add"} icon={<MdAdd />} />
            </div>
            <div className="p-2 gap-4 overflow-x-auto no-scrollbar border-b">
              <table className="w-full whitespace-nowrap">
                <thead>
                  <tr className="font-semibold text-sm">
                    <td className="">Product Name</td>
                    <td>Qty</td>
                    <td>Surcharge Rate</td>
                    <td>Total</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  <tr className="space-x-3">
                    <td className="col py-2 pr-2">
                      <Input type={"select"} />
                    </td>
                    <td className="col py-2 pr-2">
                      <Input placeholder={"Quantity"} value={"Quantity"} />
                    </td>
                    <td className="col py-2 pr-2">
                      <Input type={"select"} />
                    </td>
                    <td className="col py-2 pr-2">
                      <p className="font-semibold text-sm">$</p>
                    </td>
                    <td className="col py-2 pr-2"></td>
                  </tr>
                  <tr className="space-x-3">
                    <td className="col py-2 pr-2">
                      <Input type={"select"} />
                    </td>
                    <td className="col py-2 pr-2">
                      <Input placeholder={"Quantity"} value={"24"} />
                    </td>
                    <td className="col py-2 pr-2">
                      <Input type={"select"} value={"$ 324"} />
                    </td>
                    <td className="col py-2 pr-2">
                      <p className="font-semibold text-sm">$ 32400.00</p>
                    </td>
                    <td className="col py-2 pr-2">
                      <div className="span text-red-400">
                        <RiDeleteBin6Line />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="p-4">
                <div className="ml-auto text-green-600 w-fit flex items-center gap-12 mr-6">
                    <span>Total Charges: </span>
                    <span className="font-semibold">$ 32400.00</span>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calculation;
