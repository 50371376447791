/* eslint-disable no-undef */
import { createElement, useEffect } from "react";
import { useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { GlobalState } from "./data/Context";
import ErrorPage from "./components/error-page/ErrorPage";

const generatePage = (pageName, folder) => {
  const component = () => require(`./${folder}/${pageName}`).default;
  try {
    return createElement(component());
  } catch (error) {
    return <ErrorPage />;
  }
};

const PageRender = () => {
  const { auth } = useContext(GlobalState);
  const { page, id, step } = useParams();
  const escape2 = [
      "add-product",
      "two-factor-authentication",
      "add-company",
      "add-subproduct",
    ],
    navigate = useNavigate();

  // console.log({ page, auth });

  useEffect(() => {
    // if (auth?.isAdmin) {
    //   if (["databases", "/"].includes(page)) {
    //     return navigate("/dashboard");
    //   }
    // }
    // if (!auth.isAdmin) {
    //   if (["dashboard", "all-users"].includes(page)) {
    //     return navigate("/databases");
    //   }
    // }
    if (auth?.isAuth) {
      if (["register", "login"]?.includes(page)) {
        navigate("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, auth?.isAuth, navigate]);

  if (auth.token && auth.loading) return <></>;
  // if (general?.isLoading && users.isLoading) return <Loader />;

  let pageName = "";
  if (step) {
    pageName = `${page}/${id}/${"[id]"}`;
  } else if (id) {
    if (
      (page === "products" && escape2.includes(id)) ||
      (page === "manage-company" && escape2.includes(id)) ||
      (page === "settings" && escape2.includes(id))
      ) {
      pageName = `${page}/${id}`;
    } else {
      pageName = `${page}/${"[id]"}`;
    }
  } else {
    pageName = `${page}`;
  }
  return generatePage(pageName, auth?.isAuth ? "pages" : "screens");
};

export default PageRender;
