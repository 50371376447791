import * as React from "react";
import { LineChart, areaElementClasses } from "@mui/x-charts/LineChart";
import { useYScale, useDrawingArea } from "@mui/x-charts/hooks";
import { axisClasses } from "@mui/x-charts";

const chartSettings = {
  grid: {
    horizontal: true,
  },
};
const format = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]
const LineChartGraph = ({ series, area, labelX, labelY }) => {
  return (
    <LineChart
      xAxis={[
        {
          data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
          // valueFormatter: (val) => format[val-1],
          label: labelX
        },
      ]}
      yAxis={[{
        label: labelY
      }]}
      series={
        series || [
          {
            data: [2, 7.5, 32, 5.5, 5.5, 6],
            area: area,
            color: "#2E700E",
          },
        ]
      }
      width={500}
      height={300}
      sx={{
        [`& .${areaElementClasses.root}`]: {
          fill: "url(#swich-color-id-1)",
        },
        [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
          transform: "translateX(-10px)",
        },
      }}
      bottomAxis={[
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ]}
      {...chartSettings}
    >
      <ColorSwich
        color1="#2E700E66"
        color2="#2E700E00"
        threshold={0}
        id="swich-color-id-1"
      />
      {/* <rect x={0} y={0} width={5} height="100%" fill="url(#swich-color-id-1)" /> */}
    </LineChart>
  );
};

export default LineChartGraph;

function ColorSwich({ threshold, color1, color2, id }) {
  const { top, height, bottom } = useDrawingArea();
  const svgHeight = top + bottom + height;

  const scale = useYScale(); // You can provide the axis Id if you have multiple ones
  const y0 = scale(threshold); // The coordinate of of the origine
  const off = y0 !== undefined ? y0 / svgHeight : 0;

  return (
    <defs>
      <linearGradient
        id={id}
        x1="0"
        x2="0"
        y1="0"
        y2={`${svgHeight}px`}
        gradientUnits="userSpaceOnUse" // Use the SVG coordinate instead of the component ones.
      >
        <stop
          offset={scale(5000) / svgHeight}
          stopColor={color1}
          stopOpacity={1}
        />
        <stop offset={off} stopColor={color2} stopOpacity={0} />
      </linearGradient>
    </defs>
  );
}
