import { combineReducers } from "redux";
import UserReducer from "./UserReducer";
import { errorSlice } from "./ErrorReducer";
import productReducer from "./productReducer";
import companySlice from "./companySlice";
import surcharge from "./surcharge";
import contentSlice from "./contentSlice";
import dashboard from "./dashboard";
import currencySlice from "./currencySlice";

const rootReducer = combineReducers({
  auth: UserReducer,
  error: errorSlice.reducer,
  product: productReducer,
  company: companySlice,
  surcharge: surcharge,
  content: contentSlice,
  dashboard: dashboard,
  currency: currencySlice
});

export default rootReducer;
