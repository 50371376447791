import React, { useEffect } from "react";
import Button from "../../components/button/button";
import { MdAdd } from "react-icons/md";
import { LuUpload } from "react-icons/lu";
import Input, { TextArea } from "../../components/input/input";
import { useRef } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectSurchargeTypes } from "../../data/selectors/surchargeSelector";
import {
  fetchSurchargeProducts,
  fetchSurchargeTypes,
} from "../../data/Reducers/surcharge";
import { handleError } from "../../screens/login";

const AddProduct = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const product = location.state?.product;
  const company = location.state?.activeCompany;
  const alloyTypes = useSelector(selectSurchargeTypes);

  const defaultData = {
    title: product ? product.title : "",
    category: product ? product.category : "",
    price: product?.price || "",
    // cost: product?.cost || "",
    // quantity: product?.quantity || "",
    description: product?.description || "",
    supplierName: product?.supplierName || "",
    supplierPartNumber: product?.supplierPartNumber || "",
    customerPartNumber: product?.customerPartNumber || "",
    deliveryPlant: product?.deliveryPlant || "",
    // inventory: product?.inventory || "",
    // weight: product?.weight || "",
    // alloyType: product?.alloyType || "",
  };

  const [formData, setFormData] = useState(defaultData);
  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState("");

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const addProduct = async () => {
    if (photo) {
      formData.intendedFileName = "image";
      formData.intendedFile = photo;
    }
    if (!formData.title) return toast.error("product name is required");
    if (!formData.price) return toast.error("price is required");
    setLoading(true);
    try {
      let res;
      if (product) {
        res = await axios.put(
          `api/v1/product?product=${product?._id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "company-id": company._id,
            },
          }
        );
      } else {
        res = await axios.post("api/v1/product", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "company-id": company._id,
          },
        });
      }
      setLoading(false);
      toast.success(res.data.message);
      dispatch(fetchSurchargeProducts(company?._id));
      navigate("/products");
    } catch (error) {
      setLoading(false);
      console.log(error);
      // if (!error.response) {
      //   return toast.error(error.message);
      // } else if (error.response.data.message) {
      //   return toast.error(error.response.data.message);
      // } else {
      //   return error.response?.data?.error?.forEach((err) =>
      //     toast.error(err?.msg || err?.message)
      //   );
      // }
      handleError(error, toast);
    }
  };

  useEffect(() => {
    if (!company) {
      toast.error("You don't hava an active company");
      navigate("/manage-company");
    }
  }, []);

  useEffect(() => {
    dispatch(fetchSurchargeTypes());
  }, []);
  return (
    <div className="p-4 overflow-auto no-scrollbar">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold">Products</h1>
        {/* <div className="flex items-center gap-4">
          <Button
            text={product ? "Edit" : "Save"}
            icon={<MdAdd />}
            handleButton={addProduct}
            loading={loading}
          />
        </div> */}
      </div>
      <div className="bg-white rounded-xl mt-4">
        <div className="flex items-center justify-between border-b border-gray-200 p-4">
          <div>
            <h2 className="font-semibold text-lg">Add Product</h2>
            <p className="">
              <b>Company: </b>
              <span className="text-green-700">
                {" "}
                {company?.name || "No company"}
              </span>
            </p>
          </div>
        </div>
        <div className="p-4 max-w-2xl">
          <div className="border border-gray-200 rounded-lg">
            <div className="p-2 border-b border-gray-200">
              <p className="font-semibold text-lg">Basic Information</p>
            </div>
            <div className="p-2 space-y-4">
              <Input
                placeholder="Name of Product"
                label={"Product name*"}
                name={"title"}
                value={formData.title}
                onChange={handleInput}
              />
              <Input
                placeholder="Category"
                label={"category"}
                name={"category"}
                value={formData.category}
                onChange={handleInput}
              />
              {/* <Input
                placeholder="Inventory"
                label={"inventory"}
                name={"inventory"}
                value={formData.inventory}
                onChange={handleInput}
              /> */}
              <Input
                placeholder="Supplier"
                label={"Supplier Name"}
                name={"supplierName"}
                value={formData.supplierName}
                onChange={handleInput}
              />
              {/* <Input
                selectHolder={"select alloy type"}
                label={"Alloy Type"}
                type={"select"}
                name={"alloyType"}
                value={formData.alloyType}
                onChange={handleInput}
                options={alloyTypes.docs}
              /> */}
              <Input
                placeholder="Part Number (Customer)"
                label={"Part Number Customer)"}
                name={"customerPartNumber"}
                value={formData.customerPartNumber}
                onChange={handleInput}
              />
              <Input
                placeholder="Part Number (Supplier)"
                label={"Part Number (Supplier)"}
                name={"supplierPartNumber"}
                value={formData.supplierPartNumber}
                onChange={handleInput}
              />
              <Input
                placeholder="Delivery Plant"
                label={"Delivery Plant"}
                name={"deliveryPlant"}
                value={formData.deliveryPlant}
                onChange={handleInput}
              />
              <TextArea
                label={"Description"}
                name={"description"}
                value={formData.description}
                onChange={handleInput}
              />
            </div>
          </div>
        </div>
        <AddImage handlePhoto={setPhoto} pics={product?.image?.url} />
        <div className="p-4 max-w-2xl pb-32">
          <div className="border border-gray-200 rounded-lg">
            <div className="p-2 border-b border-gray-200">
              <p className="font-semibold text-lg">Pricing</p>
            </div>
            <div className="p-2 grid md:grid-cols-2 gap-4">
              {/* <Input
                placeholder={"$ 324"}
                label={"Quantity"}
                type={"number"}
                name={"quantity"}
                value={formData.quantity}
                onChange={handleInput}
              /> */}
              <Input
                placeholder={"$ 324"}
                label={"Price*"}
                type={"number"}
                name={"price"}
                value={formData.price}
                onChange={handleInput}
              />
              {/* <Input
                placeholder={"$ 324"}
                label={"Cost*"}
                type={"number"}
                name={"cost"}
                value={formData.cost}
                onChange={handleInput}
              /> */}
              {/* <Input
                placeholder={"90"}
                label={"Weight"}
                type={"number"}
                name={"weight"}
                value={formData.weight}
                onChange={handleInput}
              /> */}
            </div>
          </div>
          <div className="flex items-center gap-4 mt-12 justify-center">
            <Button
              text={product ? "Edit" : "Save"}
              icon={<MdAdd />}
              handleButton={addProduct}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProduct;

const AddImage = ({ handlePhoto, pics }) => {
  const imageRef = useRef(null);
  const [image, setImage] = useState("");

  const handleImage = (e) => {
    const file = e.target.files[0];
    setImage(file);
    handlePhoto(file);
  };
  return (
    <div className="p-4 max-w-2xl">
      <div className="border border-gray-200 rounded-lg">
        <div className="p-2 border-b border-gray-200">
          <p className="font-semibold text-lg">Product Image</p>
        </div>
        <div className="p-2">
          <div className="relative border border-gray-200 rounded-lg flex flex-col justify-center items-center p-8 gap-6">
            <div className="h-10 w-10 rounded-full bg-gray-300 flex items-center justify-center text-xl">
              <LuUpload />
            </div>
            {(image || pics) && (
              <div className="absolute inset-0 overflow-hidden">
                <div className="absolute bg-black inset-0 opacity-10"></div>
                <div className="absolute inset-0 flex items-center justify-center flex-col gap-8">
                  <button
                    className="text-sm font-semibold px-8 h-10 rounded-full border border-white text-white"
                    onClick={() => setImage("")}
                  >
                    Cancel
                  </button>
                  <button
                    className="active text-sm font-semibold px-3 h-10 rounded-full"
                    onClick={() => imageRef.current.click()}
                  >
                    Change Image
                  </button>
                </div>
                <img
                  src={
                    image
                      ? URL.createObjectURL(image)
                      : pics || require("../../assets/pp-2.png")
                  }
                  alt=""
                  className="object-cover w-full"
                />
              </div>
            )}
            <button
              className="active text-sm font-semibold px-3 h-10 rounded-full"
              onClick={() => imageRef.current.click()}
            >
              Upload Image
            </button>
            <p className="text-sm font-semibold">
              Drop your file here or Browse
            </p>
          </div>
          <input
            type="file"
            className="hidden"
            ref={imageRef}
            onChange={handleImage}
          />
        </div>
      </div>
    </div>
  );
};
