import React, { useContext, useState } from "react";
// import Profilerounded from "../../profile-rounded/profile-rounded";
import NotificationItem from "../notification-item";
import { useNavigate } from "react-router-dom";
import { GlobalState } from "../../../data/Context";

const Notification = () => {
  const navigate = useNavigate();
  const { notification, toggleNotification } = useContext(GlobalState);
  return (
    <div className="relative flex items-end justify-center">
      <button
        id="dropdownNotificationButton"
        data-dropdown-toggle="dropdownNotification"
        className="inline-flex items-center text-sm font-medium text-center text-gray-500 hover:text-gray-900 focus:outline-none"
        type="button"
        onClick={toggleNotification}
      >
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"></path>
        </svg>
        <div class="relative flex">
          <div class="relative inline-flex w-3 h-3 bg-red-500 border-2 border-white rounded-full -top-2 right-3 dark:border-gray-900"></div>
        </div>
      </button>
      {/* <!-- Dropdown menu --> */}
      {notification && (
        <div
          className="z-20 absolute mt-2 right-0 top-4 w-[300px] bg-white divide-y divide-gray-100 rounded-lg shadow"
        >
          <div class="block px-4 py-2 font-medium text-center text-gray-700 rounded-t-lg bg-gray-50 dark:bg-gray-800 dark:text-white">
            Notifications
          </div>
          <div class="divide-y divide-gray-100 dark:divide-gray-700">
            <NotificationItem />
            <NotificationItem />
            <NotificationItem />
            <NotificationItem />
          </div>
          <div
            class="block py-2 text-sm font-medium  cursor-pointer text-center text-gray-900 rounded-b-lg bg-gray-50 hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700 dark:text-white"
            onClick={() => navigate("/notifications")}
          >
            <div class="inline-flex items-center ">
              <svg
                class="w-4 h-4 mr-2 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                <path
                  fill-rule="evenodd"
                  d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              View all
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Notification;
