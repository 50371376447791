import AnimaionLayout from "../animeLayout/animeLayout";

import { FaAngleDown, FaAngleUp } from "react-icons/fa";

import { useContext, useEffect, useState } from "react";
import { GlobalState } from "../../data/Context";

const Questions = [
  {
    question: "How do ticket get solved",
    answer:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Exercitationem odit quas a neque! Reprehenderit quos praesentium eaque atque numquam tempore.",
    open: false,
  },
  {
    question: "How do ticket get solved",
    answer:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Exercitationem odit quas a neque! Reprehenderit quos praesentium eaque atque numquam tempore.",
    open: false,
  },
  {
    question: "How do ticket get solved",
    answer:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Exercitationem odit quas a neque! Reprehenderit quos praesentium eaque atque numquam tempore.",
    open: false,
  },
  {
    question: "How do ticket get solved",
    answer:
      "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Exercitationem odit quas a neque! Reprehenderit quos praesentium eaque atque numquam tempore.",
    open: false,
  },
];

const FAQ = ({ Questionss }) => {
  const FAQItem = ({ question, answer, toggleOpen, isOpen }) => {
    return (
      <div id="contact" className="my-2 tw-p-4">
        <div className="flex items-center justify-between">
          <h2 className="my-2 font-semibold">{question}</h2>
          <span
            onClick={() => {
              // setIsOpen(false);
              toggleOpen();
            }}
            className="cursor-pointer"
          >
            {isOpen ? <FaAngleUp /> : <FaAngleDown />}
          </span>
        </div>
        {isOpen && <p className="font-light text-sm">{answer}</p>}
      </div>
    );
  };

  let [quest, setQuest] = useState([]);

  useEffect(() => {
    let nQuest = Questions?.map((faq) => ({
      title: faq.title,
      description: faq.description,
      open: false,
    }));
    setQuest(nQuest);
  }, []);

  const toggleOpen = (index) => {
    let newArray = quest.map((question, i) => {
      if (index === i) {
        return { ...question, open: !question.open };
      } else {
        return { ...question, open: false };
      }
    });
    setQuest(newArray);
  };

  console.log({ quest });
  // console.log({ Questions });

  return (
    <AnimaionLayout className="">
      <section className="flex  relative max-w-4xl r mx-auto flex-col">
        {quest?.map((faq, index) => (
          <FAQItem
            key={index}
            question={faq.title}
            answer={faq.description}
            toggleOpen={() => toggleOpen(index)}
          />
        ))}
      </section>
    </AnimaionLayout>
  );
};

export default FAQ;
