import React from "react";
import { useNavigate } from "react-router-dom";
import Input from "../components/input/input";
import Button from "../components/button/button";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "axios";
import { ReuseBox } from "./register";

const Setup = () => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      role: "",
    },
  });

  const onSubmit = (data) => {
    console.log(data, "jhj");
  };
  return (
    <div>
      <ReuseBox>
        <div className="flex h-full items-center flex-col justify-center">
          <div className="text-center max-w-xl mx-auto my-4">
            <h1 className="font-semibold text-2xl">Account Set-up</h1>
            <p className="text-sm">
              We’ll use this info to persoanlize your experience.
            </p>
          </div>
          <form className="w-4/5 mx-auto" onSubmit={handleSubmit(onSubmit)}>
            <div className="grid gap-4 w-full">
              <div className="">
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { value, onChange } }) => (
                    <Input
                      placeholder="Company Name"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                {errors.name && (
                  <p className="text-red-500 text-xs">
                    This field is required.
                  </p>
                )}
              </div>
              <div className="">
                <Controller
                  name="role"
                  control={control}
                  rules={{
                    required: "This field is required",
                  }}
                  render={({ field: { value, onChange } }) => (
                    <Input type={"select"} value={value} onChange={onChange} />
                  )}
                />
                {errors.role && (
                  <p className="text-red-500 text-xs">
                    {errors.password.message}
                  </p>
                )}
              </div>
            </div>
            <div className="mt-8">
              <Button text={"Sign in"} style={"w-full"}></Button>
            </div>
          </form>
        </div>
      </ReuseBox>
    </div>
  );
};

export default Setup;
