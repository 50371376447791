import React from "react";
import { useState } from "react";
import Chart from "react-apexcharts";

const RadialBar = () => {
  const [options, setOptions] = useState({
    chart: {
        type: 'radialBar',
    },
    labels: ['Progress'],
  });

  const series = [70];
  return <Chart options={options} series={series} type="radialBar" />;
};

export default RadialBar;
