import React from "react";
import { ReactComponent as SubScriptionLogo } from "../../assets/svg/suscription-logo.svg";
import { IoCheckmarkSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import "./pricing.css";

const Pricing = ({ plan }) => {
  const navigate = useNavigate();
  return (
    <div className="rounded-xl border border-gray-200 p-4 w-72 mx-auto subscription bg-transparent">
      <div className="relative z-20">
        <span className="plan">
          <SubScriptionLogo />
        </span>
        <p className="text-xl font-bold mt-2 plan">
          {plan?.title || "Starter"}
        </p>
        <p className="font-semibold plan">
          {plan?.description || "Perfect plan for growing teams"}
        </p>
        <h3 className="text-3xl font-bold mt-4 plan">
          ${plan?.amount || "25"}
        </h3>
        <p className="font-semibold plan">
          per {plan?.frequency || "month"} billed annually
        </p>

        <button
          className="relative z-30 text-xs font-semibold h-10 rounded-md bg-[#2E700E] text-white w-full mt-4"
          onClick={() => {
            console.log("call");
            navigate("?setting=plan&mode=check-out", { state: plan });
          }}
        >
          Upgrade to {plan?.title || "Starter"}
        </button>
        <ul className="my-4 plan">
          {plan?.features.map((feature, id) => (
            <li className="flex items-center gap-x-2 font-semibold">
              <span>
                <IoCheckmarkSharp />
              </span>
              1-5 users
            </li>
          ))}
          <li className="flex items-center gap-x-2 font-semibold">
            <span>
              <IoCheckmarkSharp />
            </span>
            Dashboards
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Pricing;
