import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  sales: [],
  revenue: [],
  card: {},
  indicator: [],
  loading: false,
  error: null,
};

const dashboardSlice = createSlice({
  name: "content",
  initialState,
  reducers: {
    setSales: (state, { payload }) => {
      state.sales = payload.data;
    },
    setRevenue: (state, { payload }) => {
      state.revenue = payload.data;
    },
    setCard: (state, { payload }) => {
      state.card = payload.data;
    },
    setIndicator: (state, { payload }) => {
      state.indicator = payload.data;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCard, setRevenue, setIndicator, setSales } =
  dashboardSlice.actions;

export default dashboardSlice.reducer;

export const fetchSalesStats = (company) => async (dispatch) => {
  try {
    const res = await axios.get(`api/v1/product/dashboard-stat?type=sales`, {
      headers: {
        "company-id": company,
      },
    });
    dispatch(setSales(res.data));
  } catch (err) {
    console.log({ fetchSalesStats: err });
  }
};

export const fetchRevenueStats = (company) => async (dispatch) => {
  try {
    const res = await axios.get(`api/v1/product/dashboard-stat?type=revenue`, {
      headers: {
        "company-id": company,
      },
    });
    dispatch(setRevenue(res.data));
  } catch (err) {
    console.log({ fetchContents: err });
  }
};

export const fetchCardStats = (company) => async (dispatch) => {
  try {
    const res = await axios.get(`api/v1/product/dashboard-stat?type=card`, {
      headers: {
        "company-id": company,
      },
    });
    dispatch(setCard(res.data));
  } catch (err) {
    console.log({ fetchContents: err });
  }
};

export const fetchIndicatorStats = (company) => async (dispatch) => {
  try {
    const res = await axios.get(
      `api/v1/product/dashboard-stat?type=indicator`,
      {
        headers: {
          "company-id": company,
        },
      }
    );
    dispatch(setIndicator(res.data));
  } catch (err) {
    console.log({ fetchContents: err });
  }
};
