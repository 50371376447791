import { createSelector } from "@reduxjs/toolkit";

const contentSlice = (state) => state.content;

export const selectContent = createSelector(
  [contentSlice],
  (content) => content.content
);

export const selectFaq = createSelector(
  [contentSlice],
  (content) => content.faq
);

export const selectContentLoading = createSelector(
  [contentSlice],
  (loading) => loading.loading
);

export const selectContentError = createSelector(
  [contentSlice],
  (error) => error.error
);

export const selectContentCategory = createSelector(
  [contentSlice],
  (category) => category.contentCategories
);
